import "./styling/bootstrap.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./index.css";

import { Auth } from "aws-amplify";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import { cognitoConfig } from "./config/Cognito";
import * as serviceWorker from "./serviceWorker";

Auth.configure(cognitoConfig);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<Router>
    <App />
</Router>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
