import React, { ReactNode } from "react";
import {
    Button, Col, Container, InputGroup, Row, Form, Alert, Table, ButtonGroup
} from "react-bootstrap";
import {
    CaretUpFill as UpIcon,
    CaretDownFill as DownIcon,
    Plus as PlusIcon,
    X as CrossIcon
} from "react-bootstrap-icons";
import styled from "styled-components";
import "rc-slider/assets/index.css";
import { mkConfig, generateCsv, download } from "export-to-csv";
import moment from "moment";

import * as constants from "../constants";
import { getModelDomainInformation, getPageDomainInformation, makeSlotRequest, postPageDomainInformation } from "../utils/Requests";
import RandomField from "../components/fields/RandomField";
import { arrayMove, generateGuid, shuffle } from "../utils/Utils";
import MetadataCarousel from "../components/MetadataCarousel";
import Branding from "../config/Branding";
import { CarouselRow, CentralCol, FieldValue } from "../components/SharedStyled";

const ModelRow = styled(Row)`
    background-color: ${Branding.superDarkBackground};
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 5px;
    width: 100%;
`;

const SelectedEntityCol = styled(Col)`
    text-align: center;
    padding: 0;
`;

const ParametersAlert = styled(Alert)`
    position: absolute;
    top: 75px;
    right: 15px;
    z-index: 10;
    min-width: 200px;
    height: 42px;
`;

const ArrowButton = styled(Button)`
    padding: 3px;
`;

const DeleteButton = styled(Button)`
    padding: 6px;
`;

const AddButton = styled(Button)`
    padding: 6px;
`;

type PageManagerState = {
    availablePageInfo: Array<Record<string, any>>,
    availableModelInfo: Array<Record<string, any>>,
    showSuccess: boolean,
    view: string,
    errorMsg: string
    userId: string,
    seedId: string,
    selectedPageName: string,
    selectedPageStatus: string,
    selectedPageType: string,
    selectedPageModels: string,
    selectedPageModelOrder: string[],
    selectedPageOrderAlgorithm: string,
    items: Record<string, any>
}

type PageManagerProperties = {
    standalone?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

class PageManager extends React.Component<PageManagerProperties, PageManagerState> {

    private OVERVIEW = "Overview";
    private NEW = "New";
    private EDIT = "Edit";

    private availableStatus = ["Live", "Test", "In Development"];
    private availableTypes = ["Homepage", "Asset Page", "Channel Page", "Category Page", "General Page"];
    private availableAlgorithms = ["Fixed", "Random", "KPI", "User"];

    constructor(props: PageManagerProperties) {
        super(props);

        this.state = {
            availablePageInfo: [],
            availableModelInfo: [],
            showSuccess: false,
            view: this.OVERVIEW,
            errorMsg: "",
            userId: "",
            seedId: "",
            selectedPageName: "",
            selectedPageStatus: "Live",
            selectedPageType: "",
            selectedPageModels: "",
            selectedPageModelOrder: [],
            selectedPageOrderAlgorithm: "",
            items: {}
        };
    }

    public componentDidMount(): void {
        this.reset();
    }

    private reset(): void {
        this.requestPageDomainInfo();
        this.requestModelDomainInfo();
    }

    private async requestPageDomainInfo(): Promise<void> {
        await getPageDomainInformation(this.props.customerConfig).then((response: Array<Record<string, any>>) => {
            this.setState({
                availablePageInfo: response
            });
        });
    }

    private async requestModelDomainInfo(): Promise<void> {
        await getModelDomainInformation(this.props.customerConfig).then((response: Array<Record<string, any>>) => {
            this.setState({
                availableModelInfo: response
            });
        });
    }

    private closeSuccess = (): void => {
        this.setState({
            showSuccess: false
        });
    }

    private switchToNew = (): void => {
        this.setState({
            view: this.NEW,
            selectedPageName: "",
            selectedPageType: "Homepage",
            selectedPageModels: "",
            selectedPageModelOrder: [],
            selectedPageOrderAlgorithm: "Fixed",
            selectedPageStatus: "Live"
        });
    }

    private switchToOverview = (): void => {
        this.setState({
            availableModelInfo: [],
            showSuccess: false,
            view: this.OVERVIEW,
            errorMsg: "",
            userId: "",
            seedId: ""
        }, this.reset);
    }

    private switchToEdit = (row: Record<string, any>): void => {
        this.setState({
            view: this.EDIT,
            selectedPageName: row["page_name"],
            selectedPageType: row["page_type"],
            selectedPageModels: row["selected_models"],
            selectedPageModelOrder: row["model_order"].split(", "),
            selectedPageOrderAlgorithm: row["ordering_algorithm"],
            selectedPageStatus: row["page_status"]
        });
    }

    private setPageName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            selectedPageName: event.currentTarget.value
        });
    }

    private setPageType = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            selectedPageType: event.currentTarget.value
        });
    }

    private setStatus = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            selectedPageStatus: event.currentTarget.value
        });
    }

    private setAlgorithm = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            selectedPageOrderAlgorithm: event.currentTarget.value
        }, this.getItems);
    }

    private setSeedId = (callbackValue: string, submit: boolean) => {
        this.setState({
            seedId: callbackValue,
            items: {}
        }, this.getItems);
    }

    private setUserId = (callbackValue: string, submit: boolean) => {
        this.setState({
            userId: callbackValue,
            items: {}
        }, this.getItems);
    }

    private exportToCsv = () => {
        const availableModels = this.state.availableModelInfo;

        availableModels.forEach((model) => {
            model["created_by"] = model["audit_info"]["created_by"];
            model["date_created"] = model["audit_info"]["date_created"];
            model["updated_by"] = model["audit_info"]["updated_by"];
            model["date_updated"] = model["audit_info"]["date_updated"];

            model["model_type"] = model["model_type"].toUpperCase();
            model["overlay"] = model["overlay"].split("=")[1];
            model["variant"] = model["variant"].split("=")[1];

            delete model["audit_info"];
        });

        const timestamp = moment(new Date().toUTCString()).format(constants.YYMMDD_FORMAT);
        const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: `available_models_${this.props.customerConfig.slug}_${timestamp}`});
        const csv = generateCsv(csvConfig)(availableModels);
        download(csvConfig)(csv);
    }

    private elementAtEdge(key: string, top: boolean): boolean {
        const elements = this.state.selectedPageModelOrder;
        const position = elements.findIndex(obj => obj === key);

        return top ? position === 0 : position === elements.length - 1;
    }

    private moveElement(key: string, up: boolean): void {
        let elements = this.state.selectedPageModelOrder;
        const position = elements.findIndex(obj => obj === key);
        const newPosition = up ? position - 1 : position + 1;
        elements = arrayMove(elements, position, newPosition);

        this.setState({
            selectedPageModelOrder: elements
        }, this.getItems);
    }

    private deleteElement(key: string): void {
        let elements = this.state.selectedPageModelOrder;
        elements = elements.filter(obj => obj !== key);

        this.setState({
            selectedPageModelOrder: elements
        }, this.getItems);
    }

    private updateFixedModelOrder = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const modelOrder = this.state.selectedPageModelOrder;
        const position = parseInt(event.currentTarget.id.replace("dropdown-model-", ""));
        modelOrder[position] = event.currentTarget.value;

        this.setState({
            selectedPageModelOrder: modelOrder,
            selectedPageModels: modelOrder.join(", ")
        }, this.getItems);
    }

    private updateModelOrder = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const modelOrder = this.state.selectedPageModelOrder;
        const modelName = event.currentTarget.id.replace("model-", "");

        if (!event.currentTarget.checked) {
            this.deleteElement(modelName);
        } else {
            modelOrder.push(modelName);
            this.setState({
                selectedPageModelOrder: modelOrder,
                selectedPageModels: modelOrder.join(", ")
            }, this.getItems);
        }
    }

    private addElement = (): void => {
        const modelOrder = this.state.selectedPageModelOrder;
        const availableModels = this.state.availableModelInfo.filter((item) => { return !modelOrder.includes(item["playlist_name"]); });
        modelOrder.push(availableModels[0]["playlist_name"]);

        this.setState({
            selectedPageModelOrder: modelOrder
        }, this.getItems);
    }

    private savePage = () => {
        const modelsMap: Record<string, any> = {};

        this.state.selectedPageModelOrder.forEach((model, i) => {
            modelsMap[`${i}`] = {
                "model_name": model
            };
        });

        const pageBody: Record<string, any> = {
            "page_name": this.state.selectedPageName,
            "page_type": this.state.selectedPageType,
            "selected_models": this.state.selectedPageModels,
            "model_order": this.state.selectedPageModelOrder.join(", "),
            "ordering_algorithm": this.state.selectedPageOrderAlgorithm,
            "page_status": this.state.selectedPageStatus,
            "audit_info": {
                "created_by": this.props.username,
                "updated_by": this.props.username
            },
            "models": modelsMap,
            "dedupe": true, // Overridden to true in lambda
            "deletable": true // Overridden to true in lambda
        };

        try {
            postPageDomainInformation(this.props.customerConfig, pageBody).then(() => {
                this.setState({
                    showSuccess: true
                });
            });

        } catch (error) {
            this.setState({
                errorMsg: "Error saving page"
            });
        }
    }

    private renderOrderedModelSelection(): ReactNode {
        const modelOrder: Array<string> = this.state.selectedPageModelOrder;
        const modelRows = <>
            {modelOrder.map((selectedModel: string, i) => {
                const elementAtTop = this.elementAtEdge(selectedModel, true);
                const elementAtBottom = this.elementAtEdge(selectedModel, false);
                const availableModels = this.state.availableModelInfo.filter((item) => {
                    if (item["playlist_name"] === selectedModel) {
                        return true;
                    } else {
                        return !modelOrder.includes(item["playlist_name"]);
                    }
                });

                return <Row style={{paddingTop: "10px"}} key={`${i}-${selectedModel}`}>
                    <Col md="10">
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Model</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                as="select"
                                id={`dropdown-model-${i}`}
                                title="Model"
                                onChange={this.updateFixedModelOrder}
                                value={selectedModel}
                                style={{padding: "0.375rem 0.25rem"}}
                            >
                                {
                                    Object.values(availableModels).map((model: Record<string, any>, i) => {
                                        return (
                                            <option key={`${i}`} value={`${model["playlist_name"]}`}>
                                                {model["playlist_name"]}
                                            </option>
                                        );
                                    })
                                }
                            </Form.Control>
                        </InputGroup>
                    </Col>
                    <Col md={2} className="d-flex justify-content-end">
                        <ButtonGroup style={{ marginRight: "10px" }}>
                            <ArrowButton
                                variant="secondary"
                                disabled={elementAtTop}
                                onClick={() => { this.moveElement(selectedModel, true); }}
                            >
                                <UpIcon size="20" />
                            </ArrowButton>
                            <ArrowButton
                                variant="secondary"
                                disabled={elementAtBottom}
                                onClick={() => { this.moveElement(selectedModel, false); }}
                            >
                                <DownIcon size="20" />
                            </ArrowButton>
                        </ButtonGroup>

                        <DeleteButton
                            disabled={this.state.selectedPageModelOrder.length === 1}
                            variant="danger"
                            onClick={() => { this.deleteElement(selectedModel); }}
                        >
                            <CrossIcon size="22" />
                        </DeleteButton>
                    </Col>
                </Row>;
            })}
        </>;

        return modelRows;
    }

    private renderModelSelection(): ReactNode {
        const modelOrder: Array<string> = this.state.selectedPageModelOrder;
        const leftCol: Array<ReactNode> = [];
        const rightCol: Array<ReactNode> = [];
        this.state.availableModelInfo.forEach((model, i) => {
            const playlistName: string = model["playlist_name"];

            const checkbox = <Form.Check
                type="checkbox"
                label={playlistName}
                id={`model-${playlistName}`}
                checked={modelOrder.indexOf(playlistName) > -1}
                onChange={this.updateModelOrder}
            />;

            if (i % 2 === 0) {
                leftCol.push(checkbox);
            } else {
                rightCol.push(checkbox);
            }
        });

        return <Row className="d-flex justify-content-center">
            <Col md={5}>
                {leftCol}
            </Col>
            <Col md={5}>
                {rightCol}
            </Col>
        </Row>;
    }

    private getItems = async () => {
        const modelMap: Array<Record<string, any>> = [];

        this.state.selectedPageModelOrder.forEach((model: string, i) => {
            const modelInfo = this.state.availableModelInfo.filter((info) => { return info["playlist_name"] === model; });
            modelMap.push(modelInfo[0]);
        });

        const stateItems: Array<Record<string, any>> = [];
        let itemsProcessed = 0;
        modelMap.forEach(async (modelInfo) => {
            const playlistName = modelInfo["playlist_name"];
            let modelRequestURL = modelInfo["slot_url"].replace("{user_id}", this.state.userId).replace("{seed_id}", this.state.seedId);
            modelRequestURL += "&ignore=portal";
            try {
                const itemsRequest = await makeSlotRequest(modelRequestURL, true);

                itemsRequest.json().then((responseJson) => {
                    stateItems[playlistName] = responseJson["items"];
                    itemsProcessed++;
                }).then(() => {
                    if(itemsProcessed === modelMap.length) {
                        this.setState({
                            items: stateItems
                        });
                    }
                });
            } catch (error) {
                //Whatever.
            }
        });

    }

    private renderPreview(): ReactNode {
        let modelMap: Array<Record<string, any>> = [];

        this.state.selectedPageModelOrder.forEach((model: string, i) => {
            const modelInfo = this.state.availableModelInfo.filter((info) => { return info["playlist_name"] === model; });
            modelMap.push(modelInfo[0]);
        });

        if (this.state.selectedPageOrderAlgorithm === "Random") {
            modelMap = shuffle(modelMap);
        }

        return <>
            {modelMap.map((modelInfo) => {
                const playlistName = modelInfo["playlist_name"];
                return <ModelRow>
                    <Col>
                        <Row>
                            <Col style={{textAlign: "left"}}><b>{playlistName}</b></Col>
                        </Row>
                        <Row>
                            <SelectedEntityCol md>
                                {this.state.items[playlistName] && <MetadataCarousel
                                    key={JSON.stringify(this.state.items[playlistName])}
                                    items={this.state.items[playlistName]}
                                    customer={this.props.customerConfig}
                                    username={this.props.username}
                                    groups={this.props.groups}
                                    lessItems
                                    hasEntity={true}
                                    hideMissingText
                                />}
                            </SelectedEntityCol>
                        </Row>
                    </Col>
                </ModelRow>;
            })}
        </>;
    }

    private renderContent(): ReactNode {
        switch (this.state.view) {
            case this.OVERVIEW:
                return (
                    <div>
                        <>
                        <Row>
                            <Table striped bordered responsive size="sm">
                                <thead>
                                    <tr>
                                        <th>Page Name</th>
                                        <th>Page Type</th>
                                        <th>Ordering</th>
                                        <th>Status</th>
                                        <th>Selected Models</th>
                                        <th />
                                    </tr>
                                </thead>
                                {this.state.availablePageInfo.length > 0 ? (<tbody>
                                    {this.state.availablePageInfo.map(row => (
                                        <tr key={generateGuid()}>
                                            <td key={row.page_name + "name"}>
                                                {row.page_name}
                                            </td>
                                            <td key={row.page_name + "type"}>
                                                {row.page_type}
                                            </td>
                                            <td key={row.page_name + "ordering"}>
                                                {row.ordering_algorithm}
                                            </td>
                                            <td key={row.playlist_name + "status"}>
                                                {row.page_status}
                                            </td>
                                            <td key={row.playlist_name + "models"}>
                                                {row.selected_models}
                                            </td>
                                            {/* <td key={row.playlist_name + "audit"}>
                                                {"Created By " + row.audit_info.created_by}
                                            </td> */}
                                            <td key={row.playlist_name + "edit"}>
                                                <Button onClick={() => {this.switchToEdit(row);}}>Edit</Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>) :
                                (
                                    <tbody />
                                )}
                            </Table>
                        </Row>
                        <Row className="d-flex justify-content-end">
                            <Button onClick={this.exportToCsv}>Export to CSV</Button>
                            <Button style={{marginLeft: "5px"}}onClick={this.switchToNew}>
                                Add new page
                            </Button>
                        </Row>
                        </>
                    </div>
                );
            case this.NEW:
            case this.EDIT:
                return <>
                    <Row style={{paddingTop: "20px"}}>
                        <Col md="4">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Page Name</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FieldValue
                                    id="pageNameInput"
                                    type="string"
                                    placeholder="Page Name"
                                    value={this.state.selectedPageName}
                                    onChange={this.setPageName}
                                />
                            </InputGroup>
                        </Col>
                        <Col md="3">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Page Type</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-type"
                                    title="Type"
                                    onChange={this.setPageType}
                                    value={this.state.selectedPageType}
                                    style={{padding: "0.375rem 0.25rem"}}
                                >
                                    {
                                        this.availableTypes.map((type, i) => {
                                            return (
                                                <option key={`${i}`} value={`${type}`}>
                                                    {type}
                                                </option>
                                            );
                                        })
                                    }
                                </Form.Control>
                            </InputGroup>
                        </Col>
                        <Col md="2">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Status</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-status"
                                    title="Status"
                                    onChange={this.setStatus}
                                    value={this.state.selectedPageStatus}
                                    style={{padding: "0.375rem 0.25rem"}}
                                >
                                    {
                                        this.availableStatus.map((status, i) => {
                                            return (
                                                <option key={`${i}`} value={`${status}`}>
                                                    {status}
                                                </option>
                                            );
                                        })
                                    }
                                </Form.Control>
                            </InputGroup>
                        </Col>
                        <Col md="3">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Ordering</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-alg"
                                    title="Algortithm"
                                    onChange={this.setAlgorithm}
                                    value={this.state.selectedPageOrderAlgorithm}
                                    style={{padding: "0.375rem 0.25rem"}}
                                >
                                    {
                                        this.availableAlgorithms.map((alg, i) => {
                                            return (
                                                <option key={`${i}`} value={`${alg}`} disabled={alg === "KPI" || alg === "User"}>
                                                    {alg}
                                                </option>
                                            );
                                        })
                                    }
                                </Form.Control>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "10px"}} className="d-flex justify-content-center">
                        <Col md="6">
                            <Row className="d-flex justify-content-center">
                                <Col md="11" style={{textAlign: "center"}}>
                                    <b>Model {this.state.selectedPageOrderAlgorithm === "Fixed" ? "Order" : "Selection"} </b>
                                </Col>
                                <Col md="1">
                                    {this.state.selectedPageOrderAlgorithm === "Fixed" &&
                                        <AddButton variant="secondary" onClick={this.addElement}>
                                            <PlusIcon size="22" />
                                        </AddButton>
                                    }
                                </Col>
                            </Row>
                            {this.state.selectedPageOrderAlgorithm === "Fixed" && this.renderOrderedModelSelection()}
                            {this.state.selectedPageOrderAlgorithm !== "Fixed" && this.renderModelSelection()}
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "10px"}} className="d-flex justify-content-end">
                        <Col className="d-flex justify-content-end">
                            <Button  style={{marginLeft: "5px"}} onClick={this.savePage}>Save Page</Button>
                            <Button variant="secondary" style={{marginLeft: "5px"}} onClick={this.switchToOverview}>Return to Overview</Button>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "10px", marginTop: "10px", borderTop: "thin solid white"}}>
                        <Col>
                            <RandomField
                                customer={this.props.customerConfig}
                                type={constants.USER}
                                includeIcon
                                initialValue={this.state.userId}
                                onChangeCallback={this.setUserId}
                            />
                        </Col>
                        <Col>
                            <RandomField
                                customer={this.props.customerConfig}
                                type={constants.SEED}
                                includeIcon
                                initialValue={this.state.seedId}
                                onChangeCallback={this.setSeedId}
                                showName
                            />
                        </Col>
                    </Row>
                    <CarouselRow style={{paddingTop: "10px"}}>
                        <CentralCol>
                            <h5 className="mx-auto">Page Preview</h5>
                            {this.renderPreview()}
                        </CentralCol>
                    </CarouselRow>
                </>;
            default:
                return null;
        }
    }


    public render(): ReactNode {
        return (
            <>
                <ParametersAlert
                    variant='success'
                    show={this.state.showSuccess}
                    onClose={this.closeSuccess}
                    dismissible
                >
                    Page saved
                </ParametersAlert>
                <Container className="mw-100" key="model-manager" style={{paddingTop: "15px"}}>
                    <Row className="d-flex justify-content-center">
                        <Col md="11">
                            <h4>Page Manager - {this.state.view}</h4>
                            {this.renderContent()}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default PageManager;