import React, { ReactNode } from "react";
import { Button, Card, Col, Container, InputGroup, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import {
    Plus as PlusIcon,
    Search as SearchIcon
} from "react-bootstrap-icons";
import styled from "styled-components";

import { entityTypeaheadSearch, getAssociationsByEntityId, getEntitiesReportDomainInformation, lookupItemsList } from "../utils/Requests";
import Logger from "../utils/Logger";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Branding from "../config/Branding";
import { SearchResults } from "../components/SearchResults";
import * as utils from "../utils/Utils";
import moment from "moment";
import { FRIENDLY_DATETIME_FORMAT } from "../constants";
import ItemEntityAssociationModal from "../components/entity/ItemEntityAssociationModal";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const CentralCol = styled(Col)`
    text-align: center;
`;

const EntityInfoCol = styled(Col)`
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    background-color: ${Branding.superDarkBackground};
    padding: 0;
`;

const ResultsSpinnerDiv = styled.div`
    position: absolute;
    top: 25%;
    right: 49%;
    z-index: 10;
`;

const AssociatedItemsHeader = styled(Row)`
    margin: 10px 0px 10px 0px;
`;

type EntitiesReportState = {
    entityReport: Array<Record<string, any>>,
    lastUpdated: string,
    selectedEntityName: string,
    selectedEntityType: string,
    selectedEntityId: string,
    reportLoading: boolean,
    reportLoaded: boolean,
    loading: boolean,
    loaded: boolean,
    error?: Error | null,
    items: Record<string, any>[],
    showAssociationModal: boolean
    entitySearchResults: Record<string, any>[],
    entitySearching: boolean
}

type EntitiesReportProps = {
    standalone?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

interface EntitiesReportProperties extends EntitiesReportProps, RouteComponentProps {}

class EntitiesReport extends React.Component<EntitiesReportProperties, EntitiesReportState> {

    constructor(props: EntitiesReportProperties) {
        super(props);

        this.state = {
            entityReport: [],
            lastUpdated: "",
            selectedEntityName: "",
            selectedEntityType: "",
            selectedEntityId: "",
            reportLoading: false,
            reportLoaded: false,
            loading: false,
            loaded: false,
            items: [],
            showAssociationModal: false,
            entitySearchResults: [],
            entitySearching: false
        };
    }

    public componentDidMount(): void {
        this.requestReport();
    }

    private handleError(error: unknown): void {
        if (error instanceof Error) {
            Logger.error(error);
            this.setState({
                loading: false,
                loaded: true,
                error
            });
        } else {
            const unknownError = new Error("An unknown error occurred");
            Logger.error(unknownError);
            this.setState({
                loading: false,
                loaded: true,
                error: unknownError
            });
        }
    }

    private async requestReport(): Promise<void>  {
        this.setState({
            loading: true
        });

        try {
            const entityReport = await getEntitiesReportDomainInformation(
                this.props.customerConfig
            );

            if (entityReport.length > 0) {
                let selectedEntityId, selectedEntityName, selectedEntityType = "";
                if (entityReport[0]["value"].length > 0) {
                    selectedEntityId = entityReport[0]["value"][0]["entity_id"];
                    selectedEntityName = entityReport[0]["value"][0]["entity_name"];
                    selectedEntityType = entityReport[0]["value"][0]["entity_type"];
                }

                this.setState({
                    entityReport: entityReport[0]["value"],
                    lastUpdated: entityReport[0]["dateCreated"],
                    selectedEntityName,
                    selectedEntityId,
                    selectedEntityType,
                    reportLoading: false,
                    reportLoaded: true
                }, this.requestEntityAssociations);
            } else {
                this.setState({
                    reportLoading: false,
                    reportLoaded: true
                }, this.requestEntityAssociations);
            }
        } catch (err) {
            if (err instanceof Error) {
                this.handleError(err);
            }
        }
    }

    private async requestEntityAssociations(): Promise<void> {
        const {customerConfig } = this.props;

        this.setState({
            loading: true,
            loaded: false
        });

        try {
            const response = await getAssociationsByEntityId(customerConfig, this.state.selectedEntityId);

            if (response.status !== 200) {
                if (response.status === 404) {
                    throw new Error("404: Not Found");
                } else {
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        const errorJson = await response.json();
                        this.handleError(errorJson);
                    } else {
                        const errorText = await response.text();
                        this.handleError(new Error(errorText));
                    }
                }
                return;
            }

            const result = await response.json();

            if (result) {
                const itemsToLookup = result.map((item: Record<string, any>) => item["thingId"]);

                if (itemsToLookup.length === 0) {
                    this.setState({
                        items: [],
                        error: null,
                        loading: false,
                        loaded: true
                    });
                    return;
                }

                const lookupResponse = await lookupItemsList(customerConfig, itemsToLookup.join(","), true);

                if (lookupResponse.ok) {
                    const lookupResult = await lookupResponse.json();
                    this.setState({
                        items: lookupResult["items"] || [],
                        error: null,
                        loading: false,
                        loaded: true
                    });
                } else {
                    throw new Error(`Error ${lookupResponse.status}: ${lookupResponse.statusText}`);
                }
            } else {
                this.setState({
                    items: [],
                    error: null,
                    loading: false,
                    loaded: true
                });
            }
        } catch (error) {
            this.handleError(error);
        }
    }

    private handleTypeaheadChange = (selected: EntityAssociation[]): void => {
        if (selected.length > 0) {
            const selectedEntity = selected[0];
            this.selectEntity(selectedEntity["entityId"], selectedEntity["entityName"], selectedEntity["entityType"]);
        } else{
            this.setState({
                items: [],
                selectedEntityId: "",
                selectedEntityName: "",
                selectedEntityType: ""
            });
        }
    }

    private handleEntitySearch = async (query: string): Promise<void> => {
        this.setState({
            entitySearching: true
        });

        const searchResults = await entityTypeaheadSearch("", query);

        const formattedResults:Record<string, any>[] = [];

        searchResults.forEach((result) => {
            const formatted = result as Record<string, any>;
            formatted["entityNameType"] = `${result.entityName} (${result.entityType})`;
            formattedResults.push(formatted);
        });

        this.setState({
            entitySearchResults: formattedResults.slice(0, 10),
            entitySearching: false
        });
    }

    private selectEntity(entityId: string, entityName: string, entityType: string): void {
        this.setState({
            selectedEntityId: entityId,
            selectedEntityName: entityName,
            selectedEntityType: entityType,
            loading: true,
            loaded: false
        }, this.requestEntityAssociations);
    }

    private renderEntityReport(): ReactNode {
        const {error, reportLoading, reportLoaded, entityReport} = this.state;

        if (error) {
            return <Row className="justify-content-center">
                Error - {error.message}
            </Row>;
        }

        if (reportLoading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (!reportLoading && !reportLoaded) {
            return <Row className="justify-content-center">
                Report will appear here
            </Row>;
        } else {
            return <Row>
                <Col>
                <Row className="justify-content-center">
                    <h5 style={{textAlign: "center"}}>Entities Report</h5>
                </Row>
                <Row className="justify-content-center">
                    <p>Last Updated: {moment(new Date(this.state.lastUpdated).toUTCString()).format(FRIENDLY_DATETIME_FORMAT)}</p>
                </Row>
                <Row className="justify-content-center">
                    <Col>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text><SearchIcon /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <AsyncTypeahead
                            id="entity-typeahead"
                            labelKey={"entityNameType"}
                            filterBy={() => true} // Already filtered by API request
                            multiple={false}
                            onSearch={this.handleEntitySearch}
                            options={this.state.entitySearchResults}
                            isLoading={this.state.entitySearching}
                            placeholder={"Search for entity, or select one below"}
                            onChange={(selected: Option[]) => { this.handleTypeaheadChange(selected as EntityAssociation[]); }}
                            minLength={2}
                            clearButton
                        />
                    </InputGroup>
                    </Col>
                </Row>
                {(entityReport.length === 0) &&
                <Row className="justify-content-center" style={{marginTop: "5px"}}>
                    No entities associated for this customer
                </Row>}
                {(entityReport.length > 0) && <Row>
                    <Col style={{overflowY: "scroll", height: "90vh"}}>
                    <Table striped bordered style={{borderCollapse: "separate"}}>
                        <thead style={{position: "sticky", top: 0,background: "black"}}>
                            <tr style={{position: "sticky", top: 0,background: "black"}}>
                                <th style={{position: "sticky", top: 0, background: "black", border: "thin solid #282828"}}>Type</th>
                                <th style={{position: "sticky", top: 0, background: "black", border: "thin solid #282828" }}>Name</th>
                                <th style={{position: "sticky", top: 0, background: "black", border: "thin solid #282828"}}>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {entityReport.map(row => (
                                <tr style={{cursor: "pointer", background: row.entity_id === this.state.selectedEntityId ? Branding.greyBlue : undefined}}
                                    key={row.entity_id}
                                    onClick={() => {this.selectEntity(row.entity_id, row.entity_name, row.entity_type);}}
                                >
                                    <td key={row.entity_id + "type"}>
                                        {utils.convertToTitle(row["entity_type"])}
                                    </td>
                                    <td key={row.entity_id + "name"}>
                                        {row["entity_name"]}
                                    </td>
                                    <td key={row.entity_id + "count"}>
                                        {row["count"]}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    </Col>
                </Row>}
                </Col>
            </Row>;
        }
    }

    private displayAssociationModal(show: boolean): void {
        this.setState({
            showAssociationModal: show
        });
    }

    public render(): ReactNode {
        const {loading, loaded, items } = this.state;

        return (
            <>
                <ItemEntityAssociationModal
                    customer={this.props.customerConfig}
                    showModal={this.state.showAssociationModal}
                    closeCallback={() => {
                        this.displayAssociationModal(false);
                    }}
                    username={this.props.username}
                    groups={this.props.groups}
                    selectedEntityId={this.state.selectedEntityId}
                    selectedEntityName={this.state.selectedEntityName}
                    selectedEntityType={this.state.selectedEntityType}
                />
                <Container className="mw-100" style={{margin: "5px"}}>
                    <Row className="justify-content-center" style={{margin: "0px"}}>
                        <CentralCol md={3}>
                            {this.renderEntityReport()}
                        </CentralCol>
                        <EntityInfoCol md={9} className="justify-content-center">
                            {loading && <ResultsSpinnerDiv>
                                <Spinner animation="border" style={{ color: "white" }} />
                            </ResultsSpinnerDiv>}
                            <Card.Header style={{ padding: "0.25rem 0.75rem", fontSize: "1.5rem", color: "white" }}>
                                <AssociatedItemsHeader>
                                    <Col md={6}>Associated Items</Col>
                                    <Col md={6} className="d-flex justify-content-end">
                                        <OverlayTrigger
                                            placement="auto"
                                            overlay={<Tooltip id="add-assoc-tooltip">Add items to association</Tooltip>}
                                        >
                                            <Button
                                                variant="primary"
                                                type="button"
                                                onClick={() => {
                                                    this.displayAssociationModal(true);
                                                }}
                                            >
                                                <PlusIcon size="22"/>
                                            </Button>
                                        </OverlayTrigger>
                                    </Col>
                                </AssociatedItemsHeader>
                            </Card.Header>
                            {loaded && <div style={{ opacity: loading ? 0.5 : 1, overflow: "auto", height: "90vh" }}>
                                <Card.Body>
                                    <SearchResults
                                        customer={this.props.customerConfig}
                                        showMetadataLink={true}
                                        items={items}
                                        noResultsText={"No associations for this entity"}
                                        username={this.props.username}
                                        groups={this.props.groups}
                                    />
                                </Card.Body>
                            </div>}
                        </EntityInfoCol>
                    </Row>
                </Container>
            </>
        );
    }
}
export default withRouter(EntitiesReport);