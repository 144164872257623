import React, { ReactNode, RefObject } from "react";
import { Button, ButtonGroup, Col, Container, Form, InputGroup, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import {
    SortAlphaDown as SortAtoZIcon,
    SortAlphaDownAlt as SortZtoAIcon,
    SortDown as SortPopularityIcon
} from "react-bootstrap-icons";
import AssociationGrid from "../components/lanebuilder/AssociationGrid";
import SearchForm from "../components/lanebuilder/SearchForm";
import SearchTable from "../components/lanebuilder/SearchTable";
import MultiTaggingView from "../components/entity/MultiTaggingView";
import { getEntityAssociations, getGenres, getHeroItemMetadata, getItemMetadata, itemBrowse, itemSearch } from "../utils/Requests";
import { getThingFromMetadataIfSelected, seedIdCheck } from "../utils/Utils";
import Logger from "../utils/Logger";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Branding from "../config/Branding";

const EditorialRow = styled(Row)`
    background-color: ${Branding.superDarkBackground};
    border-radius: 3px;
    margin: 10px 0;
    padding: 5px 5px;
    background: ${Branding.nearBlack};
`;

const SearchDiv = styled.div`
    overflow: auto;
    height: 75vh;
    width: 100%;
    background: ${Branding.nearBlack};
`;

const SearchCountDiv = styled.div`
    text-align: center;
    width: 100%;
    margin: 5px;
    height: 20px;
`;

const Associations = styled.div`
    overflow: auto;
    height: 75vh;
    width: 100%;
    border-radius: 3px;
    background: ${Branding.nearBlack};
    margin-bottom: 5px;
`;

const SpinnerDiv = styled.div`
    position: absolute;
    top: 39%;
    right: 50%;
    z-index: 10;
`;

const BlockingDiv = styled.div<BlockingDivProps>`
    pointer-events: ${props => props.blocking ? "none" : "all"};
    opacity: ${props => props.blocking ? 0.5 : 1};
    width: 100%;
`;

const SearchBrowseTabs = styled(Tabs)`
    margin: 0;
`;

type MultiTaggingState = {
    alertMsg: string,
    alertType: "success" | "warning",
    selectedEditorialID: string,
    selectedEditorialName: string,
    selectedItems: Array<SearchItem>,
    selectedItemsMetaData?: Array<Record<string, any>>,
    originalItems: Array<EntityAssociation>,
    removedItems: Array<SearchItem>,
    searchResults: Array<SearchItem>,
    noResults: boolean,
    loading: boolean,
    showModal: boolean,
    unsaved: boolean,
    genres: [],
    selectedGenre: string,
    browseResults: Array<SearchItem>,
    originalBrowseResults: Array<SearchItem>,
    noBrowseResults: boolean,
    sortOrder: string
}

type MultiTaggingProps = {
    standalone?: boolean,
    metadataAccess?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

interface MultiTaggingProperties extends MultiTaggingProps, RouteComponentProps { }

class MultiTagging extends React.Component<MultiTaggingProperties, MultiTaggingState> {

    private searchFormElement: RefObject<SearchForm>;
    private SORT_POPULARITY = "popularity";
    private SORT_A_TO_Z = "atoz";
    private SORT_Z_TO_A = "ztoa";

    constructor(props: MultiTaggingProperties) {
        super(props);

        this.searchFormElement = React.createRef<SearchForm>();

        this.state = {
            alertMsg: "",
            alertType: "success",
            selectedEditorialID: "",
            selectedEditorialName: "",
            selectedItems: [],
            originalItems: [],
            searchResults: [],
            removedItems: [],
            noResults: false,
            loading: true,
            showModal: false,
            unsaved: false,
            genres: [],
            selectedGenre: "",
            browseResults: [],
            originalBrowseResults: [],
            noBrowseResults: false,
            sortOrder: this.SORT_POPULARITY
        };
    }

    public componentDidMount(): void {
        this.getGenres();
        setTimeout(() => { this.populateItems(); }, 500);
    }

    private getGenres = async (): Promise<void> => {
        this.setState({
            genres: []
        });
        if (this.props.customerConfig) {
            await getGenres(this.props.customerConfig).then((response) => {
                if (response.ok) {
                    return response.json().then((response) => {
                        const genresList = response.items[0]["genres"];
                        if (!genresList.includes("All Genres")) genresList.unshift("All Genres");
                        this.setState({
                            genres: genresList,
                            selectedGenre: "All Genres"
                        });
                    });
                } else {
                    return Promise.reject(response);
                }
            });
        }
    }

    private async populateItems() {
        const { customerConfig } = this.props;

        try {
            const response = await getEntityAssociations(customerConfig, "");

            if (response.ok) {
                const responseJSON = await response.json();
                const originalAssociations = responseJSON;

                const items = responseJSON.map((item: EntityAssociation) => {
                    return {
                        "id": seedIdCheck(customerConfig.name, item.thingId)
                    };
                });

                const enrichedItems = await getHeroItemMetadata(customerConfig, items);

                this.setState({
                    originalItems: originalAssociations,
                    selectedItems: enrichedItems,
                    loading: false,
                    unsaved: false
                });
            } else {
                throw new Error("Error fetching entity associations");
            }
        } catch (error) {
            Logger.log("Error in populateItems:", error);
        }
    }


    public reset = (): void => {
        this.setState({
            originalItems: [],
            searchResults: [],
            selectedItems: [],
            selectedItemsMetaData: [],
            noResults: false,
            loading: true,
            showModal: false,
            unsaved: false
        }, () => {
            if (this.searchFormElement.current) {
                this.searchFormElement.current.reset();
            }

            this.populateItems();
        });
    }

    private resetSearch = (): void => {
        this.setState({
            searchResults: []
        });
    }

    private search = async (searchTerm: string): Promise<void> => {
        const { customerConfig } = this.props;

        const searchResults = await itemSearch(customerConfig, searchTerm);

        this.setState({
            searchResults,
            noResults: searchResults.length === 0
        });
    }

    private addToGroup = (item: SearchItem): void => {
        const { customerConfig } = this.props;
        const { selectedItems } = this.state;

        item["id"] = seedIdCheck(customerConfig.name, item.id);

        const newSelection = [...selectedItems, item];

        this.setState({
            selectedItems: newSelection,
            unsaved: true
        }, () => {
            this.getItemMetadata();
        });
    }

    private removeFromGroup = (itemToRemove: SearchItem): void => {
        const { selectedItems, removedItems } = this.state;

        const newSelection = selectedItems.filter((item) => {
            return item !== itemToRemove;
        });

        removedItems.push(itemToRemove);

        this.setState({
            selectedItems: newSelection,
            removedItems,
            unsaved: true
        }, () => {
            this.getItemMetadata();
        });
    }

    private async getItemMetadata(): Promise<void> {
        const { customerConfig } = this.props;
        const { selectedItems } = this.state;
        const ItemsMetadata: Array<Record<string, any>> = [];

        for (let i = 0; i < selectedItems.length; i++) {
            const selectedItem = selectedItems[i];

            if (selectedItem.id) {
                const newMetadata = await getItemMetadata(customerConfig, selectedItem.id).then(async (response) => {
                    const responseJSON = await response.json();
                    return responseJSON.items[0];
                });

                ItemsMetadata.push({
                    [customerConfig.name]: newMetadata
                });
            }
        }

        this.setState({
            selectedItemsMetaData: ItemsMetadata
        });
    }

    private tabChange = (event: string): void => {
        if (event === "browse") {
            this.setState({
                searchResults: [],
                noResults: false,
                browseResults: [],
                noBrowseResults: false
            }, this.browse);
        } else {
            this.setState({
                searchResults: [],
                noResults: false,
                browseResults: [],
                noBrowseResults: false
            });
        }
    }

    private handleGenreChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        this.setState({
            selectedGenre: event.target.value
        }, this.browse);
    }

    private browse = async (): Promise<void> => {
        const { customerConfig } = this.props;

        const browseResults = await itemBrowse(customerConfig, this.state.selectedGenre);

        browseResults.forEach((result, i) => {
            result["order"] = i;
        });

        if (this.state.sortOrder === this.SORT_A_TO_Z) {
            browseResults.sort((a: SearchItem, b: SearchItem) => a.name.localeCompare(b.name));
        } else if (this.state.sortOrder === this.SORT_Z_TO_A) {
            browseResults.sort((a: SearchItem, b: SearchItem) => b.name.localeCompare(a.name));
        } else {
            browseResults.sort((a: SearchItem, b: SearchItem) => a.order! - b.order!);
        }

        this.setState({
            browseResults,
            noBrowseResults: browseResults.length === 0
        });
    }

    private changeSortOrder(newOrder: string): void {
        this.setState({
            sortOrder: newOrder
        });

        if (newOrder === this.SORT_A_TO_Z) {
            this.state.browseResults.sort((a: SearchItem, b: SearchItem) => a.name.localeCompare(b.name));
        } else if (newOrder === this.SORT_Z_TO_A) {
            this.state.browseResults.sort((a: SearchItem, b: SearchItem) => b.name.localeCompare(a.name));
        } else {
            this.state.browseResults.sort((a: SearchItem, b: SearchItem) => a.order! - b.order!);
        }
    }

    public render(): ReactNode {
        const { customerConfig, location } = this.props;
        const { loading, searchResults, noResults, selectedItems, selectedItemsMetaData, browseResults, noBrowseResults, sortOrder } = this.state;

        return (
            <div>
                <Container className="mw-100" style={{ marginTop: "10px" }}>
                    <Row>
                        <Col md={4}>
                            <SearchBrowseTabs defaultActiveKey={"search"} onSelect={this.tabChange} variant="pills">
                            <Tab key={"search"} eventKey={"search"} title={"Search"}>
                                <EditorialRow>
                                    {loading && <SpinnerDiv>
                                        <Spinner animation="border" style={{ color: "white" }} />
                                    </SpinnerDiv>}
                                    <BlockingDiv blocking={loading}>
                                        <SearchForm
                                            ref={this.searchFormElement}
                                            resetCallback={this.resetSearch}
                                            searchCallback={this.search} />
                                        <SearchDiv>
                                            <SearchTable
                                                items={searchResults}
                                                noResults={noResults}
                                                callback={this.addToGroup}
                                            />
                                        </SearchDiv>
                                        <SearchCountDiv>
                                            {searchResults.length > 0 && <>Showing {searchResults.length} results</>}
                                        </SearchCountDiv>
                                    </BlockingDiv>
                                </EditorialRow>
                            </Tab>
                            <Tab key={"browse"} eventKey={"browse"} title={"Browse"}>
                                <EditorialRow>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>Genre</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="select"
                                            id={"genreSelector"}
                                            title="Select Genre"
                                            value={this.state.selectedGenre}
                                            style={{ padding: "0.375rem" }}
                                            onChange={this.handleGenreChange}
                                        >
                                            {this.state.genres.map((genre: string, i: number) => {
                                                return (
                                                    <option value={genre} key={i}>
                                                        {genre}
                                                    </option>
                                                );
                                            })}
                                        </Form.Control>
                                        <InputGroup.Append>
                                            <ButtonGroup size="sm">
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="pop-sort-tooltip">Sort by Popularity</Tooltip>}
                                                >
                                                    <Button variant={sortOrder === this.SORT_POPULARITY ? "primary" : "dark"} onClick={() => {this.changeSortOrder(this.SORT_POPULARITY);}}>
                                                        <SortPopularityIcon size="22" />
                                                    </Button>
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="atoz-sort-tooltip">Sort by name A to Z</Tooltip>}
                                                >
                                                    <Button variant={sortOrder === this.SORT_A_TO_Z ? "primary" : "dark"} onClick={() => {this.changeSortOrder(this.SORT_A_TO_Z);}}>
                                                        <SortAtoZIcon size="22" />
                                                    </Button>
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="ztoa-sort-tooltip">Sort by name Z to A</Tooltip>}
                                                >
                                                    <Button variant={sortOrder === this.SORT_Z_TO_A ? "primary" : "dark"} onClick={() => {this.changeSortOrder(this.SORT_Z_TO_A);}}>
                                                        <SortZtoAIcon  size="22" />
                                                    </Button>
                                                </OverlayTrigger>
                                            </ButtonGroup>
                                        </InputGroup.Append>
                                        <SearchDiv>
                                            <SearchTable
                                                items={browseResults}
                                                noResults={noBrowseResults}
                                                callback={this.addToGroup}
                                            />
                                        </SearchDiv>
                                        <SearchCountDiv>
                                            {browseResults.length > 0 && <>Showing {browseResults.length} results</>}
                                        </SearchCountDiv>
                                    </InputGroup>
                                </EditorialRow>
                            </Tab>
                            </SearchBrowseTabs>
                        </Col>
                        <Col md={8} className="mx-auto">
                            <Row>
                                <Col md={12} >
                                    <MultiTaggingView
                                        key={JSON.stringify(selectedItems)}
                                        seedIds={selectedItems.map((item) => item.id)}
                                        customer={customerConfig}
                                        things={getThingFromMetadataIfSelected(selectedItemsMetaData, customerConfig.name)}
                                        origin={location.pathname.replace("/", "")}
                                    />
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col>
                                    <Associations>
                                        <AssociationGrid items={selectedItems} removeCallback={this.removeFromGroup} />
                                    </Associations>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default withRouter(MultiTagging);
