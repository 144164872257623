export const BUILD_ENVIRONMENT = process.env.REACT_APP_BUILD_ENV || "pre";
export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const COGNITO_USERPOOL_ID = process.env.REACT_APP_USERPOOL_ID;
export const COGNITO_APP_CLIENT_ID = process.env.REACT_APP_APP_CLIENT_ID;
export const API_KEY = process.env.REACT_APP_API_KEY;

export const THE_FILTER_FULL_AUTH = "thefilter-full";
export const DEMO_FULL_AUTH = "24iqdemo-full";
export const EPIX_FULL_AUTH = "epix-full";
export const UKTV_FULL_AUTH = "UKTV-full";
export const WAOO_FULL_AUTH = "waoo-full";
export const TELENOR_FULL_AUTH = "telenor-full";
export const BACKSTAGE_DEMO_FULL_AUTH = "backstagedemo-full";
export const SLOVAK_TELEKOM_FULL_AUTH = "slovaktelekom-full";
export const BROADWAYHD_FULL_AUTH = "broadwayhd-full";
export const FLOWSPORTS_FULL_AUTH = "flowsports-full";
export const TASTEMADE_FULL_AUTH = "tastemade-full";
export const VIRGINMEDIA_FULL_AUTH = "virginmedia-full";
export const KAN_FULL_AUTH = "kan-full";
export const RTVE_FULL_AUTH = "rtve-full"; // read-write accounts
export const RTVE_READ_AUTH = "rtve-read"; // read-only accounts



// URLs
export const OPERATIONAL_CANONICAL_ID_URL = `https://operational.${BUILD_ENVIRONMENT}.thefilter.com/canonical/id`;
export const OPERATIONAL_SEARCH_URL = `https://operational.${BUILD_ENVIRONMENT}.thefilter.com/search`;
export const OPERATIONAL_ENTITY_CREATION_URL = `https://operational.${BUILD_ENVIRONMENT}.thefilter.com/entity`;
export const OPERATIONAL_CONFIG_URL = `https://operational.${BUILD_ENVIRONMENT}.thefilter.com/config`;
export const OPERATIONAL_PAGE_CONFIG_URL = `https://operational.${BUILD_ENVIRONMENT}.thefilter.com/pageConfig`;
export const OPERATIONAL_MODEL_CONFIG_URL = `https://operational.${BUILD_ENVIRONMENT}.thefilter.com/modelConfig`;
export const OPERATIONAL_COMPOSER_URL = `https://operational.${BUILD_ENVIRONMENT}.thefilter.com/composer`;
export const OPERATIONAL_QUICKSIGHT_URL = `https://operational.${BUILD_ENVIRONMENT}.thefilter.com/quicksight`;
export const OPERATIONAL_SLOT_INFO_URL = `https://operational.${BUILD_ENVIRONMENT}.thefilter.com/slotInfo?customer={customer}`;
export const OPERATIONAL_RANDOM_URL = `https://operational.${BUILD_ENVIRONMENT}.thefilter.com/info/random/{topic}?customer={customer}&count=100`;
export const OPERATIONAL_INFO_URL = `https://operational.${BUILD_ENVIRONMENT}.thefilter.com/info`;

export const GET_REQUEST = "GET";
export const POST_REQUEST = "POST";

export const HERO_GROUP_ENTITY_ID = "Z3JvdXBfSGVybw==";
export const HERO_GROUP_ENTITY_NAME = "Hero";
export const GROUP_ENTITY_TYPE = "group";

export const DEFAULT_ENTITY_WEIGHT = 1.0;

export const USER = "user";
export const SEED = "seed";

export const DATE_FORMAT = "YYYY-MM-DD";
export const STARTDATE_FORMAT = "YYYY-MM-DDT00:00:00Z";
export const ENDDATE_FORMAT = "YYYY-MM-DDT23:59:59Z";
export const DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
export const FRIENDLY_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const SHORTDATE_FORMAT = "DD MMM";
export const YYMMDD_FORMAT = "YYMMDD";