import React, { ReactNode } from "react";
import { Button, Col, Container, Form, InputGroup, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import { Trash as ClearIcon, Search as SearchIcon, Funnel as FilterIcon, Hash as CountIcon, Plus as PlusIcon } from "react-bootstrap-icons";
import styled from "styled-components";

import Branding from "../config/Branding";
import { entitySearch } from "../utils/Requests";
import Logger from "../utils/Logger";
import { EntityTypes } from "../enums";
import CreateNewEntityModal from "../components/entity/CreateNewEntityModal";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CentralCol } from "../components/SharedStyled";

const SearchMainRow = styled(Row)`
    background: ${Branding.transparentBackground};
    top: ${props => props.standalone ? "30px" : "63px"};
`;

const SearchFormRow = styled(Form.Row)`
    margin-top: 10px;
    margin-bottom: 5px;
`;

type EntitiesState = {
    searchTerm: string,
    searchResults: Array<Record<string, any>>,
    loading: boolean,
    loaded: boolean,
    error?: Error | null,
    entityType: string,
    resultCount: number,
    showModal: boolean
    counter: number
}

type EntitiesProps = {
    standalone?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

interface EntitiesProperties extends EntitiesProps, RouteComponentProps {}

class Entities extends React.Component<EntitiesProperties, EntitiesState> {

    private timeoutId: NodeJS.Timeout | null;

    constructor(props: EntitiesProperties) {
        super(props);
        this.timeoutId = null;

        this.state = {
            searchTerm: "",
            searchResults: [],
            loading: false,
            loaded: false,
            entityType: "",
            resultCount: 25,
            showModal: false,
            counter: 0
        };
    }

    public componentDidMount(): void {
        this.requestItems();
    }

    private update = (): void => {
        this.setState({
            loading: true,
            loaded: false
        }, this.requestItems);
    }

    private handleError(error: Error) {
        Logger.error(error);
        this.setState({
            loading: false,
            loaded: true,
            error
        });
    }

    private handleReturnFromDetail = (detailState: any): void => {
        const entityDetailsState: {
            from: string,
            name: string,
            type: string,
            count: string
        } = detailState as {
            from: string,
            name: string,
            type: string,
            count: string,
        };

        let parsedCount = 0;

        try{
            parsedCount = parseInt(entityDetailsState.count);
        }catch(err){
            if (err instanceof Error) {
                this.handleError(err);
            }
        }

        if(entityDetailsState.from === "/entitiesDetail" && this.state.counter === 0){
            this.setState({
                searchTerm: entityDetailsState.name,
                entityType: entityDetailsState.type === "All" ? "" : entityDetailsState.type,
                resultCount: parsedCount,
                counter: 1
            });
        }
    }

    public async requestItems(): Promise<void>  {
        this.setState({
            loading: true
        });

        try {
            if(this.props.history.location.state){
                await this.handleReturnFromDetail(this.props.history.location.state);
            }

            const searchResults = await entitySearch(
                this.state.entityType,
                this.state.searchTerm,
                undefined,
                this.state.resultCount
            );

            this.setState({
                searchResults: searchResults,
                loading: false,
                loaded: true
            });
        } catch (err) {
            if (err instanceof Error) {
                this.handleError(err);
            }
        }
    }

    private checkKey = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.update();
        }
        else if (this.state.searchTerm.length >= 1) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(this.update, 500);
        }
        else if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    private checkBackspaceKey = (event: React.KeyboardEvent): void => {
        if ((event.key === "Backspace" || event.key === "Delete") && this.state.searchTerm.length >= 2) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(this.update, 500);
        }
    }

    private reset = (): void => {
        this.setState({
            searchTerm: "",
            searchResults: [],
            loading: false,
            loaded: false,
            error: null
        });
    }

    private setSearchTerm = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            searchTerm: event.currentTarget.value
        });
    }

    private renderSearchResults(): ReactNode {
        const {error, loading, loaded, searchResults} = this.state;

        if (error) {
            return <Row className="justify-content-center">
                Error - {error.message}
            </Row>;
        }

        if (loading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (!loading && !loaded) {
            return <Row className="justify-content-center">
                Results will appear here
            </Row>;
        } else {
            return <Row>
                <Table striped bordered responsive size="sm">
                    <thead>
                        <tr>
                            <th>Entity Type</th>
                            <th>Entity Name</th>
                            <th>Weight</th>
                            <th>Alternate</th>
                            <th>Description</th>
                            <th>Notes</th>
                            <th>Created</th>
                            <th>Created By</th>
                            <th>Updated</th>
                            <th>Updated By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchResults.map(row => (
                            <tr key={row.id}>
                                <td key={row.id + "type"}>
                                    {row.type.replaceAll("_", " ")}
                                </td>
                                <td key={row.id + "name"}>
                                    {<a href={`entitiesDetail?entityId=${row["id"]}&searchQuery=${this.state.searchTerm}&searchType=${this.state.entityType}&resultCount=${this.state.resultCount}&customer=${this.props.customerConfig.name}`}>{row.name}</a>}
                                </td>
                                <td key={row.id + "weight"}>
                                    {row.weight}
                                </td>
                                <td key={row.id + "alternateName"}>
                                    {row.alternateName}
                                </td>
                                <td key={row.id + "description"}>
                                    {row.description}
                                </td>
                                <td key={row.id + "notes"}>
                                    {row.notes}
                                </td>
                                <td key={row.id + "dateCreated"}>
                                    {row.dateCreated.replace("T", " ")}
                                </td>
                                <td key={row.id + "createdBy"}>
                                    {row.createdBy}
                                </td>
                                <td key={row.id + "dateUpdated"}>
                                    {row.dateUpdated.replace("T", " ")}
                                </td>
                                <td key={row.id + "updatedBy"}>
                                    {row.updatedBy}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>;
        }
    }

    private setEntityType =  (event: React.ChangeEvent<HTMLInputElement>): void => {
        let entityTypeValue = event.target.value;
        if ( entityTypeValue === "all" ) entityTypeValue = "";

        this.setState({
            entityType: entityTypeValue
        });
    }

    private setResultCount = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            resultCount: parseInt(event.target.value)
        });
    }

    private handleEntityModalShow() {
        this.setState({
            showModal: true
        });
    }

    private handleEntityModalClose() {
        this.setState({
            showModal: false
        });
        this.update();
    }

    public render(): ReactNode {
        return (
            <>
            <CreateNewEntityModal
                show={this.state.showModal}
                closeCallback={() => {this.handleEntityModalClose(); }}
            />
            <Container className="mw-100">
                <SearchMainRow className="justify-content-center sticky-banner" standalone={this.props.standalone? 1 : 0}>
                    <CentralCol md={10}>
                        <SearchFormRow>
                            <Col md={1}>
                                <InputGroup style={{width: "100%"}}>
                                    <OverlayTrigger overlay={<Tooltip id="Create">Create New Entity</Tooltip>}>
                                    <Button variant="primary" type="button" onClick={()=> {this.handleEntityModalShow();}}>
                                        <PlusIcon size="22" />
                                    </Button>
                                    </OverlayTrigger>
                                </InputGroup>
                            </Col>

                            <Col md={2}>
                                <InputGroup style={{width: "100%"}}>
                                    <InputGroup.Prepend>
                                        <OverlayTrigger
                                            placement="left"
                                            overlay={<Tooltip id="type-tooltip">Filter By Entity Type</Tooltip>}
                                        >
                                            <InputGroup.Text><FilterIcon size="22" /></InputGroup.Text>
                                        </OverlayTrigger>
                                    </InputGroup.Prepend>

                                    <Form.Control
                                        as="select"
                                        id="dropdown-entity-type"
                                        title="Entity Type"
                                        value={this.state.entityType}
                                        onChange={this.setEntityType}>
                                            {Object.values(EntityTypes).map((type) => {
                                                return <option value={type.toLowerCase()} key={`entity-type-${type.toLowerCase()}`}>
                                                    {type}
                                                </option>;
                                            })}
                                    </Form.Control>
                                </InputGroup>
                            </Col>
                            <Col md={2}>
                                <InputGroup style={{width: "100%"}}>
                                    <InputGroup.Prepend>
                                        <OverlayTrigger
                                            placement="left"
                                            overlay={<Tooltip id="type-tooltip">Maximum Result Count</Tooltip>}
                                        >
                                            <InputGroup.Text><CountIcon size="22" /></InputGroup.Text>
                                        </OverlayTrigger>
                                    </InputGroup.Prepend>

                                    <Form.Control
                                        as="select"
                                        id="dropdown-count"
                                        title="Count"
                                        value={this.state.resultCount}
                                        onChange={this.setResultCount}>
                                            <option value={10} key={"count-10"}>10</option>
                                            <option value={25} key={"count-25"}>25</option>
                                            <option value={50} key={"count-50"}>50</option>
                                            <option value={100} key={"count-100"}>100</option>

                                    </Form.Control>
                                </InputGroup>
                            </Col>
                            <Col md={7}>
                                <InputGroup>
                                    <Form.Control
                                        id="searchQueryInput"
                                        type="string"
                                        value={this.state.searchTerm}
                                        onChange={this.setSearchTerm}
                                        onKeyPress={this.checkKey}
                                        onKeyUp={this.checkBackspaceKey}
                                        placeholder="Search For Entities"
                                        autoComplete="off"/>
                                    <InputGroup.Append>
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={<Tooltip id="search-tooltip">Search</Tooltip>}>
                                            <Button variant="primary" type="button" onClick={this.update}>
                                                <SearchIcon size="22"/>
                                            </Button>
                                        </OverlayTrigger>

                                        <OverlayTrigger
                                            placement="right"
                                            overlay={<Tooltip id="reset=tooltip">Reset search</Tooltip>}>
                                            <Button variant="dark" type="button" onClick={this.reset}>
                                                <ClearIcon size="22"/>
                                            </Button>
                                        </OverlayTrigger>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                        </SearchFormRow>
                    </CentralCol>
                </SearchMainRow>
                <Row className="justify-content-center" style={{overflowY: "scroll", height: "80vh"}}>
                    <CentralCol md={11}>
                        {this.renderSearchResults()}
                    </CentralCol>
                </Row>
            </Container>
            </>
        );
    }
}
export default withRouter(Entities);