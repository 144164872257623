import React, { ReactNode } from "react";
import { Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getAvailableCustomers } from "../config/Customers";
import { Globe2 as CustomerIcon } from "react-bootstrap-icons";
import styled from "styled-components";
import Branding from "../config/Branding";

const Dropdown = styled(Form.Control)`
    color: white;
    background-color: ${Branding.darkTransparentBackground};
    border-color: ${Branding.darkTransparentBackground};
`;

type CustomerDropdownProperties = {
    groups: string[],
    initialValue?: string,
    callback?: (arg0: string) => void
    standalone?: boolean
}

type CustomerDropdownState = {
    availableCustomers: CustomerConfig[]
    value: string
}


export default class CustomerDropdown extends React.Component<CustomerDropdownProperties, CustomerDropdownState> {

    constructor(props: CustomerDropdownProperties) {
        super(props);

        const availableCustomers = getAvailableCustomers(this.props.groups);

        this.state = {
            availableCustomers,
            value: this.props.initialValue || availableCustomers[0].name
        };
    }

    public componentDidMount(): void {
        if (this.props.callback && this.state.availableCustomers.length === 1) {
            this.props.callback(this.state.value);
        }
    }

    public componentDidUpdate(prevProps: CustomerDropdownProperties): void {
        if (this.props.initialValue && prevProps.initialValue !== this.props.initialValue) {
            this.setState({ value: this.props.initialValue });
        }
    }

    public reset(): void {
        this.setState({
            value: this.state.availableCustomers[0].name
        });
    }

    public handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        this.setState({
            value: event.target.value
        });
        if (this.props.callback) {
            this.props.callback(event.target.value);
        }
    }

    public getCustomer(): string {
        return this.state.value;
    }

    public render(): ReactNode {
        if (this.state.availableCustomers.length <= 1) {
            return null;
        }

        if (this.props.standalone) {
            return (
                <>
                    <Dropdown
                        as="select"
                        id={"customerSelectorStandalone"}
                        value={this.state.value}
                        onChange={this.handleOnChange}
                    >
                        {this.state.availableCustomers.map((value: CustomerConfig, i: number) => {
                            return (
                                <option value={value.name} key={i}>
                                    {value.name}
                                </option>
                            );
                        })}
                    </Dropdown>
                </>
            );
        }
        return (
            <>
                <InputGroup.Prepend>
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="customer-tooltip">Customer</Tooltip>}
                    >
                        <InputGroup.Text><CustomerIcon size="22" /></InputGroup.Text>
                    </OverlayTrigger>
                </InputGroup.Prepend>
                <Form.Control
                    as="select"
                    id={"customerSelector"}
                    title="Select Customer"
                    value={this.state.value}
                    style={{ padding: "0.375rem" }}
                    onChange={this.handleOnChange}
                >
                    {this.state.availableCustomers.sort((a,b) => { return a["name"].localeCompare(b["name"]);}).map((value: CustomerConfig, i: number) => {
                        return (
                            <option value={value.name} key={i}>
                                {value.name}
                            </option>
                        );
                    })}
                </Form.Control>
            </>
        );
    }
}
