import React, { ReactNode } from "react";
import { Alert, Button, Col, Form, InputGroup, Row, Modal, Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Trash as ClearIcon, Search as SearchIcon, XCircle } from "react-bootstrap-icons";
import styled from "styled-components";


import { SearchResults } from "../SearchResults";
import * as utils from "../../utils/Utils";
import { getSearchURL, makeSlotRequest, postAssociations } from "../../utils/Requests";
import Logger from "../../utils/Logger";

import Branding from "../../config/Branding";

const ModalHeader = styled(Modal.Header)`
    padding-bottom: 0px;
`;

const DetailsRow = styled(Row)`
    padding-top: 10px;
    padding-bottom: 10px;
`;

const SearchRow = styled(Row)`
    border-top: gray thin solid;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const FormRow = styled(Row)`
    padding-top: 5px;
    padding-bottom: 5px;
`;

const FieldName = styled(InputGroup.Text)`
    background: black;
    min-width: 75px;
`;

const CentralCol = styled(Col)`
    text-align: center;
`;

const PromoAlert = styled(Alert)`
    position: absolute;
    top: 206px;
    z-index: 10;
    min-width: 200px;
    height: 50px;
`;

const AssociationContainer = styled.div`
    background: ${Branding.draggableBackground};
    display: flex;
    padding: 6px;
    border-radius: 3px;
    min-height: 200px;
    overflow-x: scroll;
`;

const ItemContainer = styled.div`
    user-select: none;
    padding: 3px;
    border-radius: 3px;
    margin: 0px 5px 5px 0;
    background: grey;
`;

const CornerSelected = styled.div`
    position: absolute;
    float: left;
    z-index: 10;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 50px 0 0;
    border-color: ${Branding.darkRed} transparent transparent transparent;
    cursor: pointer;
`;

const CornerX = styled(XCircle)`
    color: white;
    top: -45px;
    position: relative;
    left: 5px;
`;

type ItemEntityAssociationModalProperties = {
    customer: CustomerConfig,
    showModal: boolean,
    closeCallback: () => void,
    username: string,
    groups: string[],
    selectedEntityId?: string,
    selectedEntityName?: string,
    selectedEntityType?: string
}

type ItemEntityAssociationModalState = {
    associatedItems: Array<Record<string, any>>,
    searchItems: Array<Record<string, any>>,
    searchTerm: string,
    searchLoading: boolean,
    searchLoaded: boolean,
    searchError?: Error | null,
    validated: boolean,
    showAssociationError: boolean,
    associationError?: Error | null
}

export default class ItemEntityAssociationModal extends React.Component<ItemEntityAssociationModalProperties, ItemEntityAssociationModalState> {

    private timeoutId: NodeJS.Timeout | null;

    constructor(props: ItemEntityAssociationModalProperties) {
        super(props);

        this.timeoutId = null;

        this.state = {
            associatedItems: [],
            searchTerm: "",
            searchItems: [],
            searchLoading: false,
            searchLoaded: false,
            validated: false,
            showAssociationError: false
        };
    }

    private update = (): void => {
        this.setState({
            searchLoading: true,
            searchLoaded: false
        }, this.requestItems);
    }

    private handleSearchError(searchError: Error) {
        Logger.error(searchError);
        this.setState({
            searchLoading: false,
            searchLoaded: true,
            searchError
        });
    }

    private getUrl = (includeParams = true) => {
        const customer = this.props.customer;
        let url = getSearchURL(customer);

        if (includeParams) {
            const formattedParameters = utils.getFormattedParameters(`q: ${this.state.searchTerm}`);
            url += "?_debug_metadata=true&ignore=testtool" + formattedParameters;

            url = utils.addPortalUserInfoToUrl(url, this.props.username, this.props.groups);
        }

        return url;
    }

    public async requestItems(): Promise<void>  {
        await makeSlotRequest(this.getUrl(true))
            .then((response) => {
                if (response.status !== 200) {
                    if (response.status === 404) {
                        throw new Error("404: Not Found");
                    } else {
                        const contentType = response.headers.get("content-type");
                        if (contentType && contentType.indexOf("application/json") !== -1) {
                            response.json().then((error) => {
                                this.handleSearchError(error);
                            });
                        } else {
                            response.text().then((error) => {
                                this.handleSearchError(new Error(error));
                            });
                        }
                    }
                } else {
                    response.json().then(async (result) => {
                        if (result) {
                            const items = result.items;

                            this.setState({
                                searchLoading: false,
                                searchLoaded: true,
                                searchItems: items || [],
                                searchError: null
                            });
                        }
                    });
                }
            }).catch((error) => {
                this.handleSearchError(error);
            });
    }

    private checkKey = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter" || event.key === "NumpadEnter") {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.update();
        }
        else if (this.state.searchTerm.length >= 1) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(this.update, 500);
        }
        else if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    private checkBackspaceKey = (event: React.KeyboardEvent): void => {
        if ((event.key === "Backspace" || event.key === "Delete") && this.state.searchTerm.length >= 2) {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(this.update, 500);
        }
    }

    private searchReset = (): void => {
        this.setState({
            searchTerm: "",
            searchItems: [],
            searchLoading: false,
            searchLoaded: false,
            searchError: null
        });
    }

    private setSearchTerm = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            searchTerm: event.currentTarget.value
        });
    }

    private renderSearchResults(): ReactNode {
        const {searchError, searchLoading, searchLoaded} = this.state;

        if (searchError) {
            return <Row className="justify-content-center">
                Error - {searchError.message}
            </Row>;
        }

        if (searchLoading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (!searchLoading && !searchLoaded) {
            return <Row className="justify-content-center">
                Results will appear here
            </Row>;
        }
    }

    private selectItem = (item: Record<string,any>): void => {
        let { associatedItems } = this.state;
        const itemExists = associatedItems.find((associatedItem) => { return item.id === associatedItem.id; });

        if (itemExists) {
            associatedItems = associatedItems.filter((associatedItem) => {
                return item.id !== associatedItem.id;
            });
        } else {
            associatedItems.unshift(item);
        }

        this.setState({
            associatedItems: associatedItems
        });
    }

    private saveAssociations = async () => {
        const entityAssocations: EntityAssociation[] = this.state.associatedItems.map((item) => {
            return {
                thingId: item["id"],
                thingName: item["name"],
                thingType: item["typeName"],
                entityId: this.props.selectedEntityId!,
                entityName: this.props.selectedEntityName!,
                entityType: this.props.selectedEntityType!
            };
        });

        await postAssociations(this.props.customer, entityAssocations).then((response) => {
            if (response.ok) {
                if (response.status === 200) {
                    this.resetStateAndClose();
                } else {
                    this.handleSaveError(Error(`Error during save, API response: ${response.status} - ${response.statusText}`));
                }
            }
        }).catch((error: Error) => {
            this.handleSaveError(error);
        });
    }

    private handleSaveError(error: Error) {
        Logger.error(error);
        this.setState({
            showAssociationError: true
        });
    }

    private closeError = (): void => {
        this.setState({
            showAssociationError: false
        });
    }

    private resetStateAndClose() {
        this.setState({
            associatedItems: [],
            searchTerm: "",
            searchItems: [],
            searchLoading: false,
            searchLoaded: false,
            searchError: null,
            validated: false,
            showAssociationError: false,
            associationError: null
        }, () => {
            this.props.closeCallback();
        });
    }

    private closeModal = (): void => {
        this.resetStateAndClose();
    }

    public render(): ReactNode {

        return (
            <Modal
                show={this.props.showModal}
                centered
                backdrop="static"
                dialogClassName="modal-80w"
                onHide={this.closeModal}
            >
                <ModalHeader closeButton>
                    <Modal.Title>Add Entity Associations</Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    <PromoAlert
                        variant="danger"
                        show={this.state.showAssociationError}
                        onClose={this.closeError}
                        dismissible
                    >
                        Error saving association
                    </PromoAlert>
                    <DetailsRow>
                        <Col className="mx-auto" md={4} style={{ marginLeft: "10px", marginRight: "10px"}}>
                            <Form noValidate validated={this.state.validated} onSubmit={
                                (event) => {
                                    event.preventDefault();
                                    this.setState({
                                        validated: true
                                    });

                                    const formValid = event.currentTarget.checkValidity();
                                    if (formValid) {
                                        this.saveAssociations();
                                    }
                                }}>
                            <FormRow>
                                <Col>
                                    <InputGroup hasValidation>
                                        <InputGroup.Prepend>
                                            <FieldName>Entity Name</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="input"
                                            id="entity-name"
                                            contentEditable={false}
                                            disabled
                                            style={{"padding": "0.375rem"}}
                                            value={this.props.selectedEntityName}
                                            placeholder="Entity Name"
                                        />
                                    </InputGroup>
                                </Col>
                            </FormRow>
                            <FormRow>
                                <Col>
                                    <InputGroup hasValidation>
                                        <InputGroup.Prepend>
                                            <FieldName>Entity Type</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="input"
                                            id="entity-type"
                                            contentEditable={false}
                                            disabled
                                            style={{"padding": "0.375rem"}}
                                            value={utils.convertToTitle(this.props.selectedEntityType!)}
                                            placeholder="Entity Type"
                                        />
                                    </InputGroup>
                                </Col>
                            </FormRow>

                            <FormRow>
                                <Col className="d-flex justify-content-end">
                                    <Button type="submit">Save Association</Button>
                                    <Button variant="secondary" onClick={() => {this.resetStateAndClose();}} style={{marginLeft: "5px"}}>Cancel</Button>
                                </Col>
                            </FormRow>
                            </Form>
                        </Col>
                        <Col md={8} style={{borderLeft: "thin gray solid"}}>
                            <h6>Items to Associate</h6>
                            <AssociationContainer>
                                {this.state.associatedItems.map((item) => ((
                                    <ItemContainer>
                                        <div style={{position: "relative"}}>
                                            <CornerSelected onClick={() => { this.selectItem(item); }}>
                                                <CornerX size={20} />
                                            </CornerSelected>
                                            <img
                                                alt={item.title}
                                                src={utils.getImageUrl(item)} style={this.getItemStyle()}
                                            />
                                        </div>
                                    </ItemContainer>
                                )))}
                            </AssociationContainer>
                        </Col>
                    </DetailsRow>
                    <SearchRow>
                        <CentralCol md>
                            <FormRow style={{paddingBottom: "10px"}}>
                            <Col md={10} className="mx-auto">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <FieldName>Search</FieldName>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            as="input"
                                            id="search-string"
                                            style={{"padding": "0.375rem"}}
                                            value={this.state.searchTerm}
                                            onChange={this.setSearchTerm}
                                            onKeyPress={this.checkKey}
                                            onKeyUp={this.checkBackspaceKey}
                                            placeholder="Enter Search Terms here, e.g. Title, Actor"
                                            autoComplete="off"
                                        />
                                        <InputGroup.Append>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={<Tooltip id="search-tooltip">Search</Tooltip>}>
                                                <Button variant="primary" type="button" onClick={this.update}>
                                                    <SearchIcon />
                                                </Button>
                                            </OverlayTrigger>

                                            <OverlayTrigger
                                                placement="right"
                                                overlay={<Tooltip id="reset=tooltip">Reset search</Tooltip>}>
                                                <Button variant="dark" type="button" onClick={this.searchReset}>
                                                    <ClearIcon />
                                                </Button>
                                            </OverlayTrigger>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </FormRow>
                            <div style={{overflowY: "scroll", height: "45vh"}}>
                            {this.renderSearchResults()}
                            {this.state.searchLoaded &&

                                    <SearchResults
                                        customer={this.props.customer}
                                        showMetadataLink={false}
                                        showOverlay={false}
                                        items={this.state.searchItems}
                                        username={this.props.username}
                                        groups={this.props.groups}
                                        selectCallback={this.selectItem}
                                        promotedItems={this.state.associatedItems}
                                        smallImages
                                    />
                                }
                            </div>
                        </CentralCol>
                    </SearchRow>
                </Modal.Body>
            </Modal>
        );
    }

    private getItemStyle(): Record<string, any> {
        let itemStyle: Record<string, any> = {};

        if (this.props.customer?.portraitImages) {
            itemStyle = {
                height: "175px",
                width: "unset"
            };
        } else {
            itemStyle = {
                height: "unset",
                width: "150px"
            };
        }

        return itemStyle;
    }
}
