import React, { ReactNode } from "react";
import {parse as is08601parse} from "iso8601-duration";
import { Button, ButtonGroup, ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
    CardText as ViewIcon
} from "react-bootstrap-icons";

import * as utils from "../utils/Utils";
import ViewMetadataModal from "./metadata/ViewMetadataModal";

type MetadataListGroupState = {
    showViewModal: boolean,
    showEditModal: boolean
}

type MetadataListGroupProperties = {
    customer: string,
    metadata: Record<string, any>
}

export default class MetadataListGroup extends React.Component<MetadataListGroupProperties, MetadataListGroupState> {

    constructor(props: MetadataListGroupProperties) {
        super(props);

        this.state = {
            showViewModal: false,
            showEditModal: false
        };
    }

    private toggleViewModal = () => {
        this.setState({
            showViewModal: !this.state.showViewModal
        });
    }

    private toggleEditModal = () => {
        this.setState({
            showEditModal: !this.state.showEditModal
        });
    }

    public render(): ReactNode {
        const metadata = this.props.metadata;
        const director = utils.getDirector(metadata["director"]).join(", ");
        const actors = utils.getAllActors(metadata["actor"]).join(", ");
        const producers = utils.getAllProducers(metadata["producer"]).join(", ");
        const rating = utils.getContentRating(metadata);
        const releaseYear = utils.getDatePublished(metadata);
        const videoQuality = utils.getVideoQuality(metadata);
        const schedulerChannel = utils.getSchedulerChannel(metadata);
        const genres = utils.getGenres(metadata).join(", ");
        const subGenres = utils.getSubGenres(metadata).join(", ");
        const brandName = utils.getBrandNameForEpisodes(metadata);
        const brandId = utils.getBrandIdForEpisodes(metadata);

        let mins = "";

        if ("duration" in Object.keys(metadata)) {
            const duration = metadata["duration"];

            if (duration) {
                const parsedDuration = is08601parse(duration);
                mins = parsedDuration.hours * 60 + parsedDuration.minutes;
            }
        }

        return (
            <>
                <ViewMetadataModal
                    show={this.state.showViewModal}
                    customer={this.props.customer}
                    metadata={this.props.metadata}
                    closeCallback={this.toggleViewModal}
                />

                <ListGroup variant="flush">
                    {metadata.description && <ListGroup.Item>{metadata["description"].replaceAll("<br>"," ")}</ListGroup.Item>}
                    <ListGroup.Item><b>ID</b>: {metadata["id"]}</ListGroup.Item>
                    {brandName && <ListGroup.Item><b>Brand Name</b>: {brandName}</ListGroup.Item>}
                    {brandId && <ListGroup.Item><b>Brand Id</b>: {brandId}</ListGroup.Item>}
                    {genres && <ListGroup.Item><b>Genres</b>: {genres}</ListGroup.Item>}
                    {subGenres.length > 0 && <ListGroup.Item><b>Subgenres</b>: {subGenres}</ListGroup.Item>}
                    {schedulerChannel && <ListGroup.Item><b>Scheduler Channel</b>: {schedulerChannel}</ListGroup.Item>}
                    {releaseYear !== "Unknown" && <ListGroup.Item><b>Release Year</b>: {releaseYear}</ListGroup.Item>}
                    {rating !== "Unknown" && <ListGroup.Item><b>Rating</b>: {rating}</ListGroup.Item>}
                    {director && <ListGroup.Item><b>Director</b>: {director}</ListGroup.Item>}
                    {actors && <ListGroup.Item><b>Actors</b>: {actors}</ListGroup.Item>}
                    {producers && <ListGroup.Item><b>Producers</b>: {producers}</ListGroup.Item>}
                    {mins && <ListGroup.Item><b>Duration</b>: {mins}mins</ListGroup.Item>}
                    {videoQuality && <ListGroup.Item><b>Video Quality</b>: {videoQuality}</ListGroup.Item>}
                    <ListGroup.Item>
                        <ButtonGroup>
                            <OverlayTrigger overlay={<Tooltip id="view-tooltip">View raw metadata</Tooltip>}>
                                <Button variant="secondary" onClick={this.toggleViewModal}>
                                    <ViewIcon size={20}/>
                                </Button>
                            </OverlayTrigger>
                        </ButtonGroup>
                    </ListGroup.Item>
                </ListGroup>
            </>
        );
}

}