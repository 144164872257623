export const Branding: Branding = {
    primary: "#7e51ff",
    secondary: "#b6ff00",
    transparentBackground: "rgba(0,0,0,0.75)",
    transparentGreyBackground: "rgba(40,40,40,0.75)",
    superDarkBackground: "#131922",
    darkBackground: "rgb(33,38,45)",
    darkTransparentBackground: "rgba(55,55,55,0.8)",
    darkHighlyTransparentBackground: "rgba(55,55,55,0.15)",
    draggableBackground: "#444444",
    // These are the original colours, and have since been rebranded
    greyBlue: "rgb(136, 105, 248)",
    darkRed: "rgba(113, 2, 9, 0.8)",
    darkGreen: "rgba(2, 113, 9, 0.8)",
    twentyfouriRed: "#fb0148",
    twentyfouriIndigo: "#7e51ff",
    twentyfouriLime: "#b6ff00",
    yellow: "#fbb801",
    nearBlack: "#181818",
    // These can be used via white, and black keywords, this just helps unification across the project
    white: "#ffffff",
    black: "#000000"
};

export default Branding;
