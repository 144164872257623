import React, { ReactNode } from "react";
import {
    Button, Col, Container, InputGroup,
    OverlayTrigger, Row, Tooltip, Form, Alert,
    Table,
    Modal
} from "react-bootstrap";
import {
    Copy as CopyIcon,
    Pencil as EditIcon,
    Trash as DeleteIcon
} from "react-bootstrap-icons";
import styled from "styled-components";
import "rc-slider/assets/index.css";
import { mkConfig, generateCsv, download } from "export-to-csv";
import moment from "moment";

import * as constants from "../constants";
import { deleteModelTopic, getHostURL, getModelDomainInformation, getOperationalSlotInfo, getRecFilterDomainInformation, makeSlotRequest, postModelDomainInformation } from "../utils/Requests";
import RandomField from "../components/fields/RandomField";
import { generateGuid } from "../utils/Utils";
import MetadataCarousel from "../components/MetadataCarousel";
import copy from "copy-to-clipboard";
import { Typeahead } from "react-bootstrap-typeahead";
import Branding from "../config/Branding";
import { CarouselRow, CentralCol, FieldValue } from "../components/SharedStyled";

const ParametersAlert = styled(Alert)`
    position: absolute;
    top: 75px;
    right: 15px;
    z-index: 10;
    min-width: 200px;
    height: 42px;
`;

const MLTRow = styled(Row)`
    background-color: ${Branding.superDarkBackground};
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 5px;
    width: 100%;
`;

const SelectedEntityCol = styled(Col)`
    text-align: center;
    padding: 0;
`;

type ModelManagerState = {
    availableModelInfo: Array<Record<string, any>>,
    showSuccess: boolean,
    showDelete: boolean,
    showDeleteModal: boolean,
    view: string,
    errorMsg: string,
    selectedPlaylistName: string,
    selectedPlaylistStatus: string,
    selectedPlaylistModel: string,
    selectedPlaylistVariant: string,
    selectedPlaylistOverlay: string,
    selectedPlaylistURL: string,
    selectedPlaylistDeletable: boolean,
    deletePlaylistName: string,
    userId: string,
    seedId: string,
    slotsInfo: Record<string, any>,
    availableVariants: Record<string,any>,
    availableOverlays: Record<string,any>,
    availableFilters: Record<string,any>,
    items: Array<Record<string,any>>,
    filters: Record<string, Array<Record<string, string>>>
}

type ModelManagerProperties = {
    standalone?: boolean,
    groups: string[],
    username: string,
    customerConfig: CustomerConfig
}

class ModelManager extends React.Component<ModelManagerProperties, ModelManagerState> {

    private OVERVIEW = "Overview";
    private NEW = "New";
    private EDIT = "Edit";

    private availableStatus = ["Live", "Test", "In Development"];
    private filterableModels = ["chart", "latest", "most_popular", "leaving_soon"]

    constructor(props: ModelManagerProperties) {
        super(props);

        this.state = {
            availableModelInfo: [],
            showSuccess: false,
            showDelete: false,
            showDeleteModal: false,
            view: this.OVERVIEW,
            errorMsg: "",
            selectedPlaylistName: "",
            selectedPlaylistStatus: "Live",
            selectedPlaylistModel: "",
            selectedPlaylistVariant: "",
            selectedPlaylistOverlay: "",
            selectedPlaylistURL: "",
            selectedPlaylistDeletable: false,
            deletePlaylistName: "",
            userId: "cold",
            seedId: "",
            slotsInfo: {},
            availableVariants: {},
            availableOverlays: {},
            availableFilters: {},
            items: [],
            filters: {}
        };
    }

    public componentDidMount(): void {
        this.reset();
    }

    private reset(): void {
        this.requestModelDomainInfo();
        this.requestCustomerSlotInfo();
        this.getRecFilterInfo();
    }

    private async requestModelDomainInfo(): Promise<void> {
        await getModelDomainInformation(this.props.customerConfig).then((response: Array<Record<string, any>>) => {
            this.setState({
                availableModelInfo: response
            });
        });
    }

    private async getRecFilterInfo(): Promise<void> {
        await getRecFilterDomainInformation(this.props.customerConfig).then((response: Array<Record<string, any>>) => {
            const filterState: Record<string, Array<Record<string, string>>> = {};
            const availableFilters: Record<string, any> = {};

            Object.values(response).forEach((filter: Record<string, any>) => {
                filterState[filter.topic] = [];
                availableFilters[filter.topic] = {
                    "qsp": filter.topic,
                    "label": filter.value.label,
                    "values": filter.value.values,
                    "cardinality": filter.value.cardinality
                };
            });

            this.setState({
                availableFilters,
                filters: filterState
            });
        });
    }

    private async requestCustomerSlotInfo(): Promise<void> {
        await getOperationalSlotInfo(this.props.customerConfig.slug).then((response: Record<string, any>) => {
            let slotsInfoResponse: Record<string,any>[] = [];
            const slotsInfo: Record<string, any> = {};

            for (const slotKey in response["slots"]) {
                slotsInfoResponse = slotsInfoResponse.concat(response["slots"][slotKey]);
            }

            slotsInfoResponse.forEach((slot: Record<string, any>) => {
                // Skip Email RFY for EPIX as it doesn't fit this concept.
                if (slot["slotId"] !== "6e0a51f3-5651-4c1c-a2c3-8b6479e64a24") {
                    const variants = slot["parameters"]["variant"];
                    const overlays = slot["parameters"]["overlay"];

                    let variantInfo: Record<string,any>[] = [];
                    if (variants !== undefined) {
                        variantInfo = Object.values(variants["options"]).map((variant: any) => {
                            return {
                                "name": variant["displayName"],
                                "qsp": variant["qsp"]
                            };
                        });
                    }

                    let overlayInfo: Record<string,any>[] = [];
                    if (overlays !== undefined) {
                        overlayInfo = Object.values(overlays["options"]).map((overlay: any) => {
                            return {
                                "name": overlay["displayName"],
                                "qsp": overlay["qsp"]
                            };
                        });

                    }

                    slotsInfo[slot["slotId"]] = {
                        "id": slot["slotId"],
                        "name": slot["slotName"],
                        "type": slot["type"],
                        "userId": Object.keys(slot["parameters"]).includes("userId"),
                        "seedId": Object.keys(slot["parameters"]).includes("seedIds"),
                        "variants": variantInfo,
                        "overlays": overlayInfo
                    };
                }
            });

            this.setState({
                slotsInfo
            });
        });
    }

    private closeSuccess = (): void => {
        this.setState({
            errorMsg: "",
            showSuccess: false
        });
    }

    private closeDelete = (): void => {
        this.setState({
            showDelete: false,
            errorMsg: ""
        });
    }

    private switchToNew = (): void => {
        const firstSlot = Object.values(this.state.slotsInfo)[0];

        this.setState({
            view: this.NEW,
            selectedPlaylistModel: firstSlot["id"],
            selectedPlaylistName: "",
            availableVariants: firstSlot["variants"],
            availableOverlays: firstSlot["overlays"],
            selectedPlaylistVariant: firstSlot["variants"][0]["qsp"],
            selectedPlaylistOverlay: firstSlot["overlays"][0]["qsp"],
            selectedPlaylistStatus: "Live",
            selectedPlaylistDeletable: true
        }, this.setUrl);
    }

    private switchToOverview = (): void => {
        this.setState({
            availableModelInfo: [],
            showSuccess: false,
            view: this.OVERVIEW,
            errorMsg: "",
            selectedPlaylistName: "",
            selectedPlaylistStatus: "",
            selectedPlaylistModel: "",
            selectedPlaylistVariant: "",
            selectedPlaylistOverlay: "",
            selectedPlaylistURL: "",
            selectedPlaylistDeletable: false,
            deletePlaylistName: "",
            userId: "cold",
            seedId: "",
            slotsInfo: {},
            availableVariants: {},
            availableOverlays: {},
            items: [],
            availableFilters: [],
            filters: {}
        }, this.reset);
    }

    private switchToEdit = (row: Record<string, any>): void => {
        const filterState = this.state.filters;
        Object.keys(this.state.availableFilters).forEach((filterKey) => {
            if (filterKey in row) {
                // Backwards compatability, if required
                if (typeof row[filterKey] === "string") {
                    filterState[filterKey] = [{
                        "value": `${row[filterKey]}`,
                        "label": `${row[filterKey]}`
                    }];
                } else {
                    filterState[filterKey] = row[filterKey];
                }
            }
        });

        this.setState({
            view: this.EDIT,
            selectedPlaylistModel: row["slot_id"],
            selectedPlaylistName: row["playlist_name"],
            selectedPlaylistVariant: row["variant"],
            selectedPlaylistOverlay: row["overlay"],
            selectedPlaylistStatus: row["status"],
            availableVariants: this.state.slotsInfo[row["slot_id"]]["variants"],
            availableOverlays: this.state.slotsInfo[row["slot_id"]]["overlays"],
            selectedPlaylistDeletable: row["deletable"],
            filters: filterState
        }, this.setUrl);
    }

    private setUrl = () => {
        const hostUrl = getHostURL(this.props.customerConfig);

        const slotId = this.state.selectedPlaylistModel;
        const slotInfo = this.state.slotsInfo[slotId];
        const variant = this.state.selectedPlaylistVariant;
        const overlay = this.state.selectedPlaylistOverlay;

        let slotUrl = `${hostUrl}/slots/${slotId}/items?${variant}&${overlay}`;

        const filterState = this.state.filters;

        Object.keys(filterState).forEach((key: string) => {
            if (filterState[key].length > 0) {
                const values = filterState[key].map((filter) => {
                    return filter.value;
                });
                slotUrl += `&${key}=${values}`;
            }
        });

        if (slotInfo["userId"]) {
            slotUrl += "&userId={userId}";
        }

        if (slotInfo["seedId"]) {
            slotUrl += "&seedIds={seedId}";
        }

        this.setState({
            selectedPlaylistURL: slotUrl
        }, this.makeModelRequest);
    }

    private setPlaylistName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            selectedPlaylistName: event.currentTarget.value,
            selectedPlaylistDeletable: true
        });
    }

    private setStatus = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            selectedPlaylistStatus: event.currentTarget.value
        });
    }

    private setModel = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const slotId = event.currentTarget.value;
        const slotInfo = this.state.slotsInfo[slotId];
        this.setState({
            selectedPlaylistModel: slotId,
            availableVariants: slotInfo["variants"],
            availableOverlays: slotInfo["overlays"],
            selectedPlaylistVariant: slotInfo["variants"][0]["qsp"],
            selectedPlaylistOverlay: slotInfo["overlays"][0]["qsp"]
        }, this.setUrl);
    }

    private setVariant = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            selectedPlaylistVariant: event.currentTarget.value
        }, this.setUrl);
    }

    private setOverlay = (event: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({
            selectedPlaylistOverlay: event.currentTarget.value
        }, this.setUrl);
    }

    private setFilter(key: string, selected: any[]) {

        let selectedOptions: any = [];
        selected.forEach(item => {
            selectedOptions.push(item) ;
        });

        if (selectedOptions.length === 0) {
            selectedOptions = [];
        }

        const filterState = this.state.filters;
        filterState[key] = selectedOptions;

        this.setState({
            filters: filterState
        }, this.setUrl);
    }

    private setSeedId = (callbackValue: string, submit: boolean) => {
        this.setState({
            seedId: callbackValue
        }, this.makeModelRequest);
    }

    private setUserId = (callbackValue: string, submit: boolean) => {
        this.setState({
            userId: callbackValue
        }, this.makeModelRequest);
    }

    private copyURLToClipboard = (url: string) => {
        copy(url);
    }

    private copyDynamicURLToClipboard = () => {
        copy(this.formatUrl(this.state.selectedPlaylistURL));
    }

    private exportToCsv = () => {
        const availableModels = this.state.availableModelInfo;

        availableModels.forEach((model) => {
            model["created_by"] = model["audit_info"]["created_by"];
            model["date_created"] = model["audit_info"]["date_created"];
            model["updated_by"] = model["audit_info"]["updated_by"];
            model["date_updated"] = model["audit_info"]["date_updated"];

            model["model_type"] = model["model_type"].toUpperCase();
            model["overlay"] = model["overlay"].split("=")[1];
            model["variant"] = model["variant"].split("=")[1];
            model["slot_url"] = this.formatUrl(model["slot_url"]);

            delete model["deletable"];
            delete model["audit_info"];
        });

        const timestamp = moment(new Date().toUTCString()).format(constants.YYMMDD_FORMAT);
        const csvConfig = mkConfig({ useKeysAsHeaders: true, filename: `available_models_${this.props.customerConfig.slug}_${timestamp}`});
        const csv = generateCsv(csvConfig)(availableModels);
        download(csvConfig)(csv);
    }

    private savePlaylist = () => {
        const modelBody: Record<string, any> = {
            "playlist_name": this.state.selectedPlaylistName,
            "model_type": this.state.slotsInfo[this.state.selectedPlaylistModel]["type"],
            "overlay": this.state.selectedPlaylistOverlay,
            "variant": this.state.selectedPlaylistVariant,
            "slot_url": this.state.selectedPlaylistURL,
            "status": this.state.selectedPlaylistStatus,
            "slot_id": this.state.selectedPlaylistModel,
            "deletable": this.state.selectedPlaylistDeletable,
            "audit_info": {
                "created_by": this.props.username,
                "updated_by": this.props.username
            }
        };

        Object.keys(this.state.filters).forEach((filterKey) => {
            if (this.state.filters[filterKey].length > 0) {
                modelBody[filterKey] = this.state.filters[filterKey];
            }
        });

        try {
            postModelDomainInformation(this.props.customerConfig, modelBody).then(() => {
                this.setState({
                    showSuccess: true
                });
            });

        } catch (error) {
            this.setState({
                errorMsg: "Error saving playlist"
            });
        }

    }

    private showDeleteModal(playlistName: string) {
        this.setState({
            showDeleteModal: true,
            deletePlaylistName: playlistName
        });
    }

    private handleDeleteModalClose() {
        this.setState({
            showDeleteModal: false,
            deletePlaylistName: ""
        }, () => {
            setTimeout(() => { this.reset(); }, 200);
        });
    }

    private async deletePlaylist() {
        if (this.state.deletePlaylistName) {
            try {
                const response = await deleteModelTopic(this.props.customerConfig, this.state.deletePlaylistName);
                if (response.ok) {
                    this.setState({
                        errorMsg: "",
                        showDelete: true,
                        showDeleteModal: false
                    }, this.reset);
                } else if (response.status === 403) {
                    this.setState({
                        errorMsg: "Unable to delete item",
                        showDelete: true,
                        showDeleteModal: false
                    });
                } else {
                    this.setState({
                        errorMsg: response.text,
                        showDelete: true,
                        showDeleteModal: false
                    });
                }

            } catch (error) {
                this.setState({
                    errorMsg: "Error deleting playlist",
                    showDeleteModal: false
                });
            }
        }
    }

    private async makeModelRequest() {
        if (this.state.selectedPlaylistURL !== "") {
            try {
                let modelRequestURL = this.state.selectedPlaylistURL.replace("{userId}", this.state.userId).replace("{seedId}", this.state.seedId);
                modelRequestURL += "&ignore=portal";

                const itemsRequest = await makeSlotRequest(modelRequestURL, true);

                itemsRequest.json().then(async (responseJson) => {
                    this.setState({
                        items: responseJson["items"]
                    });
                });
            } catch (error) {
                //Whatever.
            }
        }
    }

    private formatUrl(url: string) {
        if (this.props.customerConfig.slug === "rtve") {
            if (url.indexOf("format=rtve") === -1) {
                const qmIndex = url.indexOf("?") + 1;
                const updatedUrl = url.substring(0, qmIndex) + "format=rtve&" + url.substring(qmIndex);
                return updatedUrl;
            }
        }

        return url;
    }

    private renderContent(modelType: string) {
        const additionalFilters = Object.keys(this.state.filters).filter((key) => {
            return key !== "genre" && key !== "typename" && key !== "excludeGenre";
        });

        switch (this.state.view) {
            case this.OVERVIEW:
                return (
                    <div>
                        <>
                        <Row>
                            <Table striped bordered responsive size="sm">
                                <thead>
                                    <tr>
                                        <th>Playlist Name</th>
                                        <th>Type</th>
                                        <th>Set-up</th>
                                        <th>Owner</th>
                                        <th>Slot URL</th>
                                        <th />
                                        <th />
                                        <th />
                                    </tr>
                                </thead>
                                {this.state.availableModelInfo.length > 0 ? (<tbody>
                                    {this.state.availableModelInfo.map(row => (
                                        <tr key={generateGuid()}>
                                            <td key={row.playlist_name + "name"}>
                                                {row.playlist_name}
                                            </td>
                                            <td key={row.playlist_name + "type"}>
                                                {row.model_type.replaceAll("_", " ").toUpperCase()}
                                            </td>
                                            <td key={row.playlist_name + "set-up"}>
                                                {"Variant: " + row.variant.split("=")[1]}<br/>{"Overlay: " + row.overlay.split("=")[1]}
                                            </td>
                                            <td key={row.playlist_name + "owner"}>
                                                {row.owner ? row.owner : "24iQ"}
                                            </td>
                                            <td key={row.playlist_name + "slotUrl"}>
                                                {this.formatUrl(row.slot_url)}
                                            </td>
                                            {/* <td key={row.playlist_name + "audit"}>
                                                {"Created By " + row.audit_info.created_by}
                                            </td> */}
                                            <td key={row.playlist_name + "copy"}>
                                                <OverlayTrigger
                                                    placement="auto"
                                                    overlay={<Tooltip id={row.playlist_name + "url-tooltip"}>Copy URL To Clipboard</Tooltip>}>
                                                    <Button variant="secondary" onClick={() => {this.copyURLToClipboard(row.slot_url);}}><CopyIcon size={18}/></Button>
                                                </OverlayTrigger>
                                            </td>
                                            <td key={row.playlist_name + "edit"}>
                                                <OverlayTrigger
                                                    placement="auto"
                                                    overlay={<Tooltip id={row.playlist_name + "edit-tooltip"}>Edit Model</Tooltip>}>
                                                    <Button onClick={() => {this.switchToEdit(row);}}><EditIcon size={18}/></Button>
                                                </OverlayTrigger>
                                            </td>
                                            <td key={row.playlist_name + "delete"}>
                                                <OverlayTrigger
                                                    placement="auto"
                                                    overlay={row.deletable ? <Tooltip id={row.playlist_name + "delete-tooltip"}>Delete Model</Tooltip> : <div />}>
                                                    <Button variant={row.deletable ? "danger" : "outline-danger"} disabled={row.deletable ? false : true} onClick={() => {this.showDeleteModal(row.playlist_name);}}>
                                                        <DeleteIcon size={18}/>
                                                    </Button>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>) :
                                (
                                    <tbody />
                                )}
                            </Table>
                        </Row>
                        <Row className="d-flex justify-content-end">
                            <Button onClick={this.exportToCsv}>Export to CSV</Button>
                            <Button style={{marginLeft: "5px"}} onClick={this.switchToNew}>
                                Add new model playlist
                            </Button>
                        </Row>
                        </>
                    </div>
                );
            case this.NEW:
            case this.EDIT:
                return <>
                    <Row style={{paddingTop: "20px"}}>
                        <Col md="8">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Playlist Name</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FieldValue
                                    id="playlistNameInput"
                                    type="string"
                                    placeholder="Playlist Name"
                                    value={this.state.selectedPlaylistName}
                                    onChange={this.setPlaylistName}
                                />
                            </InputGroup>
                        </Col>
                        <Col md="4">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Status</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-status"
                                    title="Status"
                                    onChange={this.setStatus}
                                    value={this.state.selectedPlaylistStatus}
                                >
                                    {
                                        this.availableStatus.map((status, i) => {
                                            return (
                                                <option key={`${i}`} value={`${status}`}>
                                                    {status}
                                                </option>
                                            );
                                        })
                                    }
                                </Form.Control>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "10px"}}>
                        <Col md="4">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Model Selection</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-model"
                                    title="Model"
                                    onChange={this.setModel}
                                    value={this.state.selectedPlaylistModel}
                                >
                                    {Object.values(this.state.slotsInfo).map((slot: Record<string, any>) => {
                                        return <option value={slot["id"]}>{slot["name"]}</option>;
                                    })}
                                </Form.Control>
                            </InputGroup>
                        </Col>
                        <Col md="4">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Variant</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-variant"
                                    title="Variant"
                                    onChange={this.setVariant}
                                    value={this.state.selectedPlaylistVariant}
                                >
                                    {Object.values(this.state.availableVariants).map((variant: Record<string, any>) => {
                                        return <option value={variant["qsp"]}>{variant["name"]}</option>;
                                    })}
                                </Form.Control>
                            </InputGroup>
                        </Col>
                        <Col md="4">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Overlay</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    id="dropdown-overlay"
                                    title="Overlay"
                                    value={this.state.selectedPlaylistOverlay}
                                    onChange={this.setOverlay}
                                >
                                    {Object.values(this.state.availableOverlays).map((overlay: Record<string, any>) => {
                                        return <option value={overlay["qsp"]}>{overlay["name"]}</option>;
                                    })}
                                </Form.Control>
                            </InputGroup>
                        </Col>
                    </Row>
                    {this.filterableModels.indexOf(modelType) > -1 &&
                    <>
                        <Row style={{paddingTop: "10px"}}>
                            {this.state.availableFilters["genre"] && <Col md="4">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>{this.state.availableFilters["genre"].label} Filter</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Typeahead
                                        id="genre-typeahead"
                                        multiple={this.state.availableFilters["genre"]["cardinality"] === "multiple"}
                                        options={this.state.availableFilters["genre"].values.map((genre: Record<string, any>) => {
                                            return {"value": genre.id, "label": genre.label};
                                        })}
                                        selected={Object.values(this.state.filters["genre"]).map((genre: Record<string, any>) => {
                                            return {"value": genre.value, "label": genre.label};
                                        })}
                                        placeholder={`Select ${this.state.availableFilters["genre"].label} filter...`}
                                        onChange={(evt) => { this.setFilter("genre", evt);}}
                                        clearButton
                                    />
                                </InputGroup>
                            </Col>}
                            {this.state.availableFilters["typename"] && <Col md="4">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>{this.state.availableFilters["typename"].label} Filter</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Typeahead
                                        id="typename-typeahead"
                                        multiple={this.state.availableFilters["typename"]["cardinality"] === "multiple"}
                                        options={this.state.availableFilters["typename"].values.map((typename: Record<string, any>) => {
                                            return {"value": typename.id, "label": typename.label};
                                        })}
                                        selected={Object.values(this.state.filters["typename"]).map((typename:  Record<string, any>) => {
                                            return {"value": typename.value, "label": typename.label};
                                        })}
                                        placeholder={`Select ${this.state.availableFilters["typename"].label} filter...`}
                                        onChange={(evt) => { this.setFilter("typename", evt);}}
                                        clearButton
                                    />
                                </InputGroup>
                            </Col>}
                            {this.state.availableFilters["excludeGenre"] && <Col md="4">
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>{this.state.availableFilters["excludeGenre"].label} Filter</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Typeahead
                                        id="excludeGenre-typeahead"
                                        multiple={this.state.availableFilters["excludeGenre"]["cardinality"] === "multiple"}
                                        options={this.state.availableFilters["excludeGenre"].values.map((genre: Record<string, any>) => {
                                            return {"value": genre.id, "label": genre.label};
                                        })}
                                        selected={Object.values(this.state.filters["excludeGenre"]).map((genre: Record<string, any>) => {
                                            return {"value": genre.value, "label": genre.label};
                                        })}
                                        placeholder={`Select ${this.state.availableFilters["excludeGenre"].label} filter...`}
                                        onChange={(evt) => { this.setFilter("excludeGenre", evt);}}
                                        clearButton
                                    />
                                </InputGroup>
                            </Col>}
                        </Row>
                        {additionalFilters.length > 0 && additionalFilters.length <= 3 && <Row style={{paddingTop: "10px"}}>
                            {additionalFilters.slice(0,3).map((additionalFilter) => {
                                return <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>{this.state.availableFilters[additionalFilter].label} Filter</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Typeahead
                                            id={`${additionalFilter}-typeahead`}
                                            multiple={this.state.availableFilters[additionalFilter]["cardinality"] === "multiple"}
                                            align="left"
                                            options={this.state.availableFilters[additionalFilter].values.map((item: Record<string, any>) => {
                                                return {"value": item.id, "label": item.label};
                                            })}
                                            selected={Object.values(this.state.filters[additionalFilter]).map((item: Record<string, any>) => {
                                                return {"value": item.value, "label": item.label};
                                            })}
                                            placeholder={`Select ${this.state.availableFilters[additionalFilter].label} filter...`}
                                            onChange={(evt) => { this.setFilter(additionalFilter, evt);}}
                                            clearButton
                                        />
                                    </InputGroup>
                                </Col>;
                            })}
                        </Row>}
                        {additionalFilters.length > 3 && additionalFilters.length <= 6 && <Row style={{paddingTop: "10px"}}>
                            {additionalFilters.slice(3,6).map((additionalFilter) => {
                                return <Col>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>{this.state.availableFilters[additionalFilter].label} Filter</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Typeahead
                                            id={`${additionalFilter}-typeahead`}
                                            multiple={this.state.availableFilters[additionalFilter]["cardinality"] === "multiple"}
                                            align="left"
                                            options={this.state.availableFilters[additionalFilter].values.map((item: Record<string, any>) => {
                                                return {"value": item.id, "label": item.label};
                                            })}
                                            selected={Object.values(this.state.filters[additionalFilter]).map((item: Record<string, any>) => {
                                                return {"value": item.value, "label": item.label};
                                            })}
                                            placeholder={`Select ${this.state.availableFilters[additionalFilter].label} filter...`}
                                            onChange={(evt) => { this.setFilter(additionalFilter, evt);}}
                                            clearButton
                                        />
                                    </InputGroup>
                                </Col>;
                            })}
                        </Row>}
                    </>}
                    <Row style={{paddingTop: "10px"}}>
                        <Col>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Slot URL</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FieldValue
                                    id="slotUrlOutput"
                                    type="string"
                                    placeholder="Slot URL"
                                    readOnly
                                    value={this.formatUrl(this.state.selectedPlaylistURL)}
                                />
                                <InputGroup.Append>
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip id="search-tooltip">Copy URL</Tooltip>}>
                                        <Button variant="primary" type="button" onClick={this.copyDynamicURLToClipboard}>
                                            <CopyIcon size={18}/>
                                        </Button>
                                    </OverlayTrigger>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "10px"}}>
                        <Col>
                            <RandomField
                                customer={this.props.customerConfig}
                                type={constants.USER}
                                includeIcon
                                initialValue={this.state.userId}
                                onChangeCallback={this.setUserId}
                            />
                        </Col>
                        <Col>
                            <RandomField
                                customer={this.props.customerConfig}
                                type={constants.SEED}
                                includeIcon
                                initialValue={this.state.seedId}
                                onChangeCallback={this.setSeedId}
                                showName
                            />
                        </Col>
                    </Row>
                    <CarouselRow style={{paddingTop: "10px"}}>
                        <CentralCol>
                            <h5 className="mx-auto">Results Preview</h5>
                            {this.renderPreview()}
                        </CentralCol>
                    </CarouselRow>
                    <Row style={{paddingTop: "10px"}} className="d-flex justify-content-end">
                        <Col className="d-flex justify-content-end">
                            <Button onClick={this.savePlaylist}>Save Playlist</Button>
                            <Button variant="secondary" style={{marginLeft: "5px"}} onClick={this.switchToOverview}>Return to Overview</Button>
                        </Col>
                    </Row>
                </>;
            default:
                return null;
        }
    }


    private renderPreview(): ReactNode {
        if (!this.state.items) {
            return null;
        }

        return (
            <MLTRow>
                <SelectedEntityCol md>
                    <MetadataCarousel
                        key={JSON.stringify(this.state.items)}
                        items={this.state.items}
                        customer={this.props.customerConfig}
                        username={this.props.username}
                        groups={this.props.groups}
                        lessItems
                        hasEntity={true}
                        hideMissingText
                        itemText
                    />
                </SelectedEntityCol>
            </MLTRow>
        );
    }


    public render(): ReactNode {
        let modelType = "";
        if (this.state.selectedPlaylistModel) {
            modelType = this.state.slotsInfo[this.state.selectedPlaylistModel]["type"];
        }

        return (
            <>
                <ParametersAlert
                    variant='success'
                    show={this.state.showSuccess}
                    onClose={this.closeSuccess}
                    dismissible
                >
                    Model playlist saved
                </ParametersAlert>
                <Modal
                    show={this.state.showDeleteModal}
                    onHide={()=> { this.handleDeleteModalClose(); }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Model?</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>
                            Do you want to delete model with name: <b>{this.state.deletePlaylistName}</b>?
                            <br/>
                            <b>This cannot be undone</b>
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {this.handleDeleteModalClose();}}>Cancel</Button>
                        <Button variant="danger" onClick={() => {this.deletePlaylist();}}>Delete</Button>
                    </Modal.Footer>
                </Modal>
                <ParametersAlert
                    variant={this.state.errorMsg === "" ? "success" : "danger"}
                    show={this.state.showDelete}
                    onClose={this.closeDelete}
                    dismissible
                >
                    {this.state.errorMsg === "" ? "Model playlist deleted" : this.state.errorMsg}
                </ParametersAlert>
                <Container className="mw-100" key="model-manager" style={{paddingTop: "15px"}}>
                    <Row className="d-flex justify-content-center">
                        <Col md="11">
                            <h4>Model Manager - {this.state.view}</h4>
                            {this.renderContent(modelType)}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default ModelManager;