import React, { ReactNode, RefObject } from "react";
import { Button, Image, OverlayTrigger } from "react-bootstrap";
import styled from "styled-components";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import * as Icons from "react-bootstrap-icons";

import MetadataPopover from "./MetadataPopover";
import * as utils from "../utils/Utils";
import MetadataModal from "./MetadataModal";
import Branding from "../config/Branding";
import InfoIcon from "./InfoIcon";

const CarouselItem = styled.div`
    display: table-cell;
    vertical-align: middle;
    border-radius: 3px;
    position: relative;
    text-align: center;
`;

const MessageContainer = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
`;

const Title = styled.div`
    text-align: center;
    height: 35px;
    block-overflow: ellipsis;
    max-lines: 2;
    cursor: default;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
`;

const ArrowButton = styled(Button)`
    margin: 0px 5px 5px 5px;
    padding: 5px;
    position: absolute;
    top: calc(50% - 35px);
    background: ${Branding.darkHighlyTransparentBackground};
    border-color: ${Branding.darkHighlyTransparentBackground};
`;

const ArrowLeftButton = styled(ArrowButton)`
    left: 0px;
`;

const ArrowRightButton = styled(ArrowButton)`
    right: 0px;
`;

const CornerSelected = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 50px 0 0;
    border-color: ${Branding.darkBackground} transparent transparent transparent;
    cursor: pointer;
`;

const CornerX = styled(Icons.XCircle)`
    color: white;
    top: -45px;
    position: relative;
    left: 5px;
`;

const LiveIndicator = styled.div`
    position: absolute;
    top: 4px;
    left: 10px;
    z-index: 10;
`;

const Spacer = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    height: 30px;
`;

type MetadataCarouselProperties = {
    items: Array<Record<string, any>>
    customer?: CustomerConfig,
    hideInfoIcon?: boolean,
    modal?: boolean,
    large?: boolean,
    username: string,
    groups: string[],
    lessItems?: boolean,
    hasEntity?: boolean,
    hideMissingText?: boolean,
    itemText?: boolean,
    removeItemCallback?: (item: Record<string, any>) => void,
    openInNewTab?: boolean
}

type MetadataCarouselState = {
    showModal: boolean,
    selectedItem: Record<string, any>,
    items: Array<Record<string, any>>,
    renderKey: number
}

export default class MetadataCarousel extends React.Component<MetadataCarouselProperties, MetadataCarouselState> {

    private carouselElement: RefObject<AliceCarousel>;

    constructor(props: MetadataCarouselProperties) {
        super(props);

        this.carouselElement = React.createRef<AliceCarousel>();

        this.state = {
            showModal: false,
            selectedItem: {},
            items: this.props.items,
            renderKey: Math.random()
        };
    }

    public componentDidMount(): void {
        this.setState({
            renderKey: Math.random()
        });
    }

    public componentDidUpdate(prevProps: MetadataCarouselProperties): void {
        if (prevProps.items.length !== this.props.items.length) {
            this.setState({
                items: this.props.items
            });
        }
    }

    private getPrevItem = (e: React.MouseEvent): void => {
        if (this.carouselElement.current) {
            const currentSlide = this.carouselElement.current.state.activeIndex;
            const itemsInSlide = this.carouselElement.current.state.itemsInSlide;
            let newIndex = currentSlide - itemsInSlide;
            if (newIndex <= 0) newIndex = 0;
            this.carouselElement.current.slideTo(newIndex);
        }
    }

    private getNextItem = (e: React.MouseEvent): void => {
        if (this.carouselElement.current) {
            const currentSlide = this.carouselElement.current.state.activeIndex;
            const itemsInSlide = this.carouselElement.current.state.itemsInSlide;
            const itemsTotal = this.carouselElement.current.state.itemsCount;
            let newIndex = currentSlide + itemsInSlide;
            if (newIndex >= itemsTotal - itemsInSlide) newIndex = itemsTotal - itemsInSlide;
            this.carouselElement.current.slideTo(newIndex);
        }
    }

    private displayModal(show: boolean, item: Record<string, any> = {}): void {
        if (this.props.modal) {
            this.setState({
                showModal: show,
                selectedItem: item
            });
        }
    }

    private getItemStyle(item: Record<string, any>): Record<string, any> {
        let itemStyle: Record<string, any> = {};

        if (this.props.removeItemCallback) {
            if (this.props.customer?.portraitImages) {
                itemStyle = {
                    height: "175px",
                    width: "unset"
                };
            } else {
                itemStyle = {
                    height: "unset",
                    width: "150px"
                };
            }
        } else if (this.props.customer?.portraitImages) {
            itemStyle = {
                height: "280px",
                width: "unset",
                maxWidth: "98%"
            };
        } else {
            itemStyle = {
                height: "unset",
                width: "250px",
                maxWidth: "96%"
            };
        }

        if (this.props.modal) {
            itemStyle["cursor"] = "pointer";
        }

        if (this.props.large) {
            itemStyle["width"] = "450px";
            itemStyle["height"] = "auto";
        }

        if (item["typeName"] === "user" || item["typeName"] === "cluster") {
            itemStyle["background"] = Branding.darkBackground;
        }

        return itemStyle;
    }

    private getResponsiveSlideCount(): Record<number, any> {
        if (this.props.removeItemCallback) {
            if (this.props.customer?.portraitImages) {
                return {
                    0: { items: this.props.lessItems ? 4 : 5 },
                    1400: { items: this.props.lessItems ? 5 : 7 },
                    1650: { items: this.props.lessItems ? 6 : 8 },
                    1920: { items: this.props.lessItems ? 8 : 10 },
                    2400: { items: this.props.lessItems ? 12 : 14 },
                    3800: { items: this.props.lessItems ? 15 : 18 }
                };
            } else {
                return {
                    0: { items: this.props.lessItems ? 3 : 4 },
                    1400: { items: this.props.lessItems ? 4 : 5 },
                    1650: { items: this.props.lessItems ? 5 : 6 },
                    1920: { items: this.props.lessItems ? 6 : 8 },
                    2400: { items: this.props.lessItems ? 10 : 12 },
                    3800: { items: this.props.lessItems ? 12 : 16 }
                };
            }

        } else if (this.props.large) {
            return {
                0: { items: 3 },
                1400: { items: 4 },
                1650: { items: 5 },
                1920: { items: 6 },
                2400: { items: 8 },
                3800: { items: 9 }
            };
        } else if (this.props.customer?.portraitImages) {
            return {
                0: { items: this.props.lessItems ? 4 : 5 },
                1400: { items: this.props.lessItems ? 5 : 7 },
                1650: { items: this.props.lessItems ? 6 : 8 },
                1920: { items: this.props.lessItems ? 8 : 10 },
                2400: { items: this.props.lessItems ? 12 : 14 },
                3800: { items: this.props.lessItems ? 15 : 18 }
            };
        } else {
            return {
                0: { items: 5 },
                1400: { items: 6 },
                1650: { items: 7 },
                1920: { items: 8 },
                2400: { items: 11 },
                3800: { items: 14 }
            };
        }
    }

    private getCarouselMaxItemCount(): number {
        const responsiveSlideCount = this.getResponsiveSlideCount();
        const windowWidth = window.innerWidth;
        const slideCountKeys = Object.keys(responsiveSlideCount);

        let i = 0;
        for (i = 0; i < slideCountKeys.length; i++) {
            if (parseInt(slideCountKeys[i]) > windowWidth && i > 0) {
                return responsiveSlideCount[parseInt(slideCountKeys[i - 1])].items;
            }
        }
        if (i === slideCountKeys.length) return responsiveSlideCount[3800].items;
        return responsiveSlideCount[0].items;
    }

    private removeItemCallback(item: Record<string, any>) {
        if (this.props.removeItemCallback) {
            this.props.removeItemCallback(item);
        }
    }

    public render(): ReactNode {
        if (this.state.items.length === 0) {
            return (
                <MessageContainer>
                    {!this.props.hideMissingText && "No other entities associated"}
                    {this.props.itemText && "No Results"}
                    <Spacer />
                </MessageContainer>
            );
        }

        const itemCount = this.getCarouselMaxItemCount();
        const carouselItems: Array<Record<string, any>> = [];

        this.state.items.forEach((item) => {
            carouselItems.push(item);
        });

        while (carouselItems.length < itemCount) {
            carouselItems.push({ typeName: "spacer" });
        }

        const items = carouselItems.map((item, i) => {
            return (
                <div style={{ display: "inline" }}>
                    {(item["typeName"] !== "user" && item["typeName"] !== "cluster" && item["typeName"] !== "spacer") &&
                        <CarouselItem
                            style={this.getItemStyle(item)}
                            onClick={() => { this.displayModal(true, item); }}
                        >
                            {this.props.customer && !this.props.hideInfoIcon &&
                                <InfoIcon
                                    customer={this.props.customer}
                                    itemId={item["id"]}
                                    openInNewTab
                                />
                            }
                            <LiveIndicator>{utils.isLiveItem(item)}</LiveIndicator>
                            <OverlayTrigger
                                key={i}
                                placement={"auto"}
                                overlay={
                                    <MetadataPopover item={item} />}
                            >
                                <>
                                    <Image
                                        src={utils.getImageUrl(item)}
                                        alt={item["id"]}
                                        onError={(event) => { utils.getPlaceholderImage(event, item); }}
                                        onDragStart={(event) => { event.preventDefault(); }}
                                        key={`${this.props.customer}-${i}`}
                                        rounded
                                        style={this.getItemStyle(item)}
                                    />
                                    {this.props.removeItemCallback &&
                                        <CornerSelected onClick={() => { this.removeItemCallback(item); }}>
                                            <CornerX size={20} />
                                        </CornerSelected>
                                    }
                                </>
                            </OverlayTrigger>
                            <Title>{item["name"] || "Unknown"}</Title>
                            <Title>{item["typeName"] || "Unknown"}</Title>
                            <Title><b>{utils.getBrandNameForEpisodes(item)}</b></Title>
                        </CarouselItem>
                    }
                    {(item["typeName"] === "user" || item["typeName"] === "cluster") &&
                        <CarouselItem style={this.getItemStyle(item)}>
                            <Image
                                src={utils.getImageUrl(item)}
                                alt={item["id"]}
                                onError={(event) => { utils.getPlaceholderImage(event, item); }}
                                onDragStart={(event) => { event.preventDefault(); }}
                                key={`${this.props.customer}-${i}`}
                                rounded
                                style={this.getItemStyle(item)}
                            />
                        </CarouselItem>
                    }
                    {item["typeName"] === "spacer" && <CarouselItem style={this.getItemStyle(item)} />}
                </div>
            );
        });

        if (carouselItems.length) {
            return (
                <>
                    {this.props.modal && <MetadataModal
                        item={this.state.selectedItem}
                        customer={this.props.customer}
                        showModal={this.state.showModal}
                        closeCallback={() => { this.displayModal(false); }}
                        username={this.props.username}
                        groups={this.props.groups}
                    />}
                    <div style={{ "position": "relative" }}>
                        <AliceCarousel
                            ref={this.carouselElement}
                            items={items}
                            disableButtonsControls
                            disableDotsControls
                            responsive={this.getResponsiveSlideCount()}
                            paddingLeft={35}
                            paddingRight={35}
                            renderKey={this.state.renderKey}
                        />

                        <ArrowLeftButton variant="secondary" onClick={this.getPrevItem}><Icons.CaretLeftFill /></ArrowLeftButton>
                        <ArrowRightButton variant="secondary" onClick={this.getNextItem}><Icons.CaretRightFill /></ArrowRightButton>

                    </div>
                </>
            );
        } else {
            return (
                <MessageContainer>
                    No results
                </MessageContainer>
            );
        }
    }
}
